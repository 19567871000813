interface ConfigurationInterface {
    frontendURL: string;
    backendURL: string;
    stripePublishableKey: string;
}

export const dynamicConfiguration: ConfigurationInterface = {
    frontendURL: process.env.REACT_APP_PLATFORM_FRONTEND_URL || 'https://thisisomnia.com',
    backendURL: process.env.REACT_APP_PLATFORM_BACKEND_URL || 'https://thisisomnia-backend.com',
    stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_live_51ObV3iIVpqga7aO7LY5dSBqYSGkAXdbG9aQFmYgXapvHviL08wWEVslNo8KwLBfADN1Ipu194Tk4Qb5FmZRLXALb00HUtlXCBy'
};

import './enterotppage.scss';

import {
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { FaCircleArrowLeft } from 'react-icons/fa6';
import { MdMailLock } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import ApiResponse from '../../classes/ApiResponse';
import AuthChallengeResponseRequest
  from '../../classes/authchallengeresponse/AuthChallengeResponseRequest';
import AuthChallengeResponseResponse
  from '../../classes/authchallengeresponse/AuthChallengeResponseResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/getaccountstatefromtoken/GetAccountStateFromTokenResponse';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingIcon from '../../components/LoadingIcon';
import NavigationArrowButton from '../../components/NavigationArrowButton';
import PageContent from '../../components/PageContent';
import PlatformModal from '../../components/PlatformModal';
import Constants from '../../configuration/constants';

interface EnterOTPPageProps {
  emailAddress: string;
  setShowEnterOTPPage: (state: boolean) => void;
}

const EnterOTPPage = (props: EnterOTPPageProps): ReactElement => {
  const { emailAddress, setShowEnterOTPPage } = props;

  const [verificationCode, setVerificationCode] = useState<string[]>(Array.from({ length: Constants.otpLength }, () => ''));
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [noCodeModalVisible, setNoCodeModalVisible] = useState<boolean>(false);
  const [isSubmittingAuthChallenge, setIsSubmittingAuthChallenge] = useState<boolean>(false);
  const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');
  const [authChallengeAttempts, setAuthChallengeAttempts] = useState<number>(0);
  const [codeSubmitDisabled, setCodeSubmitDisabled] = useState<boolean>(false);

  const navigate = useNavigate();

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current[selectedIndex]?.focus();
  }, [selectedIndex]);

  const submitAuthChallenge = async (submittedOTP: string) => {
    setAuthChallengeAttempts(authChallengeAttempts+1);
    setCustomerErrorMessage('');
    setIsSubmittingAuthChallenge(true);
    const authChallengeResponseRequest = new AuthChallengeResponseRequest(emailAddress, submittedOTP, Cookies.get(Constants.authSessionIdTokenName));
    const authChallengeResponse: AuthChallengeResponseResponse|null = await ApiResponse.getApiResponse(
      Constants.authChallengeResponseEndpoint,
      authChallengeResponseRequest,
      AuthChallengeResponseResponse,
      null,
      null,
      false
    );
    
    if (authChallengeResponse && authChallengeResponse.authSessionId) {
      Cookies.set(Constants.authSessionIdTokenName, authChallengeResponse.authSessionId, {
        path: '/',
        secure: true, // If testing using localhost on Safari, this must be set to false
        sameSite: 'strict',
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // 1 year from now
      });
    }

    if (authChallengeResponse instanceof AxiosError || !authChallengeResponse) { // Uncaught error while validating OTP
      navigate('/authenticate');
    } else if (authChallengeResponse.authRequestExpired) { // Max attempts exceeded or auth request has expired. User must restart auth session
      setCustomerErrorMessage('Your authentication request has expired. Please go back and request a new code.')
      setCodeSubmitDisabled(true);
      setIsSubmittingAuthChallenge(false);
      setSelectedIndex(-1);
    } else if (!authChallengeResponse.idToken) { // No token returned, OTP was incorrect
      setIsSubmittingAuthChallenge(false);
      setVerificationCode(Array.from({ length: Constants.otpLength }, () => ''));
      setSelectedIndex(0);
      setCustomerErrorMessage('The entered code was incorrect. Please try again.');
    } else if (authChallengeResponse.idToken) { // Token returned, OTP was valid
      Cookies.set(Constants.jwtTokenName, authChallengeResponse.idToken, { 
        path: '/',
        secure: true, // If testing using localhost on Safari, this must be set to false
        sameSite: 'strict',
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // 1 year from now
      });
      const getAccountStateFromTokenResponse: GetAccountStateFromTokenResponse | null = await ApiResponse.getApiResponse(
        Constants.getAccountStateFromTokenEndpoint,
        null,
        GetAccountStateFromTokenResponse,
        null,
        null,
        true
      );
      if (!getAccountStateFromTokenResponse || getAccountStateFromTokenResponse instanceof AxiosError) {
        setCustomerErrorMessage('Your authentucation was auccessful, but there was an isue retrieving your account. Please reach out to our team for support. We apologise for any inconvenience.')
      } else if (getAccountStateFromTokenResponse.accountCreated && !getAccountStateFromTokenResponse.stripeOnboardingCompleted) {
        navigate(Constants.connectedAccountPagePath);
      } else if (getAccountStateFromTokenResponse.accountCreated && getAccountStateFromTokenResponse.stripeOnboardingCompleted) {
        navigate(Constants.homePagePath)
      } else {
        navigate(Constants.createAccountPagePath)
      }
    } else {
      navigate(Constants.homePagePath);
    }
    setIsSubmittingAuthChallenge(false);
  }

  const handleChange = (value: string, index: number) => {
    if (!/^[0-9]*$/.test(value)) {
      return;
    }

    setVerificationCodeValueAtIndex(value, index);

    if (selectedIndex < Constants.otpLength-1) {
      setSelectedIndex(index+1);
    } else {
      const submittedOTP = verificationCode.join('') + value;
      submitAuthChallenge(submittedOTP);
    }
  }

  const handleBackspace = (key: string, index: number) => {
    if (key !== 'Backspace') {
      return;
    }

    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = '';
    
    if (index > 0) {
      newVerificationCode[index-1] = '';
      setSelectedIndex(index-1);
    }

    setVerificationCode(newVerificationCode);
  }

  const setVerificationCodeValueAtIndex = (value: string, index: number) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);
  }

  const toggleModal = () => {
    setNoCodeModalVisible(!noCodeModalVisible);
  }
  
  return (
    <PageContent>
      <div className='lock-icon-wrapper'><MdMailLock className='lock-icon'/></div>
      <div className='verify-email-title-wrapper'><h1 className='verify-email-title'>Verify your email address</h1></div>
      <div className='enter-code-message'>We've emailed a {Constants.otpLength} digit code to:</div>
      <div className='enter-code-email-address'>{emailAddress}</div>
      <div className='enter-code-message'>Enter the code below to verify your email address</div>
      <div className='code-input-section'>
        {verificationCode.map((_, index) => (
          <input
            className={`code-input-box 
              ${(index === selectedIndex || isSubmittingAuthChallenge) ? 'highlight' : ''} 
              ${verificationCode[index] ? 'filled-in' : ''} 
              ${index !== selectedIndex ? 'input-disabled' : ''}
              ${codeSubmitDisabled ? 'submit-disabled' : ''}
            `}
            key={index}
            ref={el => inputRefs.current[index] = el}
            type='text'
            value={verificationCode[index]}
            maxLength={1}
            disabled={isSubmittingAuthChallenge || codeSubmitDisabled}
            onChange={e => handleChange(e.target.value, index)}
            onKeyDown={e => {handleBackspace(e.key, index)}}
            pattern='\d*'
          />
        ))}
      </div>
      { !isSubmittingAuthChallenge && <div className='not-received-email-message' onClick={toggleModal}>I haven't received a code</div> }
      { noCodeModalVisible && 
        <PlatformModal
          modalTitle='No code?'
          modalDescription='It can take over a minute to receive your code. In the mean time here are a few tips to get you back on track:'
          modalBody={
            <ul className='bulleted-list'>
              <li>Confirm the email address you entered is correct</li>
              <li>Check your Junk or Spam folder</li>
              <li>Press the back arrow <FaCircleArrowLeft className='no-code-modal-back-arrow' /> and request a new code</li>
            </ul>
          }
          onClose={toggleModal}
          isVisible={noCodeModalVisible}
        />
      }
      { isSubmittingAuthChallenge ?
        <LoadingIcon/>
        :
        <NavigationArrowButton
          disabled={noCodeModalVisible}
          backArrow={true}
          bottomAnchored='bottom-left'
          onClick={() => setShowEnterOTPPage(false)}
        />
      }
      { customerErrorMessage !== '' &&
        <ErrorMessage errorMessage={customerErrorMessage}/>
      }
    </PageContent>
  );
}

export default EnterOTPPage;

import { AccountType } from '../../pages/createaccount/types/types';
import { SocialMediaLinks } from '../../types/types';
import PaymentProfileDisplayData from './PaymentProfileDisplayData';

class IndividualPaymentProfileDisplayData extends PaymentProfileDisplayData {
    constructor(
        username: string,
        displayName: string,
        base64ProfileImage: string, 
        qrCodeVerb: string,
        suggestedPayValues: number[],
        thankyouMessage: string,
        socialMediaLinks: SocialMediaLinks,
        stripeConnectedAccountId: string,
        currency: string
        ) {
            super(
                username,
                displayName,
                base64ProfileImage, 
                qrCodeVerb,
                AccountType.INDIVIDUAL.value,
                suggestedPayValues,
                thankyouMessage,
                socialMediaLinks,
                stripeConnectedAccountId,
                currency
            );
        }
}

export default IndividualPaymentProfileDisplayData;

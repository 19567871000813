import './pagecontent.scss';

import React, {
  ReactElement,
  ReactNode,
  useEffect,
} from 'react';

import { useLocation } from 'react-router-dom';

interface PageContentProps {
    children: ReactNode;
    fullWidth?: boolean;
    alignItems?: 'left' | 'center';
}

const PageContent = (props: PageContentProps): ReactElement => {
    const { children, fullWidth, alignItems } = props;

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <div className={
            `page-content ${fullWidth ? 'full-width' : ''} ${alignItems === 'left' ? 'align-left' : ''}`
        }>
            {children}
        </div>
    );
};

export default PageContent;

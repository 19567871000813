import { AccountType } from '../../pages/createaccount/types/types';
import { SocialMediaLinks } from '../../types/types';
import PayoutSchedule from '../updatepayoutschedule/PayoutSchedule';
import AccountBalance from './AccountBalance';
import DailyRevenuesForCurrency from './DailyRevenueForCurrency';
import LinkedAccountData from './LinkedAccountData';
import PaymentAccountUserProfileData from './PaymentAccountUserProfileData';
import PlatformPayment from './PlatformPayment';
import PlatformPayout from './PlatformPayout';
import TransactionTotal from './TransactionTotal';

class CompanyPaymentAccountUserProfileData extends PaymentAccountUserProfileData {
    constructor(
        username: string,
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        stripeConnectedAccountId: string,
        suggestedPayValues: number[],
        thankyouMessage: string,
        socialMediaLinks: SocialMediaLinks,
        currency: string,
        receivedAmountPerCurrency: TransactionTotal[],
        accountBalancePerCurrency: AccountBalance[],
        dailyRevenuesPerCurrencyData: DailyRevenuesForCurrency[],
        payments: PlatformPayment[],
        payouts: PlatformPayout[],
        payoutSchedule: PayoutSchedule,
        linkedAccountsData: LinkedAccountData[]
    ) {
        super(
            username,
            displayName,
            base64ProfileImage,
            qrCodeVerb,
            AccountType.COMPANY.value,
            stripeConnectedAccountId,
            suggestedPayValues,
            thankyouMessage,
            socialMediaLinks,
            currency,
            receivedAmountPerCurrency,
            accountBalancePerCurrency,
            dailyRevenuesPerCurrencyData,
            payments,
            payouts,
            payoutSchedule,
            linkedAccountsData
        );
    }
}

export default CompanyPaymentAccountUserProfileData;

import './connectedaccountpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { FaStripe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';

import ApiResponse from '../../classes/ApiResponse';
import CreateStripeAccountSessionResponse
  from '../../classes/createstripeaccountsession/CreateStripeAccountSessionResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/getaccountstatefromtoken/GetAccountStateFromTokenResponse';
import DefaultNavbar from '../../components/navbar/DefaultNavbar';
import NavigationArrowButton from '../../components/NavigationArrowButton';
import PageContent from '../../components/PageContent';
import PageDefault from '../../components/PageDefault';
import Constants from '../../configuration/constants';
import StripeConfig from '../../configuration/stripeConfig';
import HelperMethods from '../../helpers/HelperMethods';

const ConnectedAccountPage = (): ReactElement => {
    const [showStripeOnboardingElement, setShowStripeOnboardingElement] = useState<boolean>(false);
    const [stripeConnectInstance] = useState<StripeConnectInstance>(() => {
        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                null,
                true
            );
        };

        const fetchClientSecret = async (): Promise<string> => {
            return await ApiResponse.getApiResponse(
                Constants.createStripeAccountSessionEndpoint,
                null,
                CreateStripeAccountSessionResponse, 
                null,
                null,
                true
            ).then(response => {
                return response ? response.clientSecret : '';
            });
        };
        
        getAccountStateFromToken().then(response => {
            if (!response || response instanceof AxiosError) {
                navigate(Constants.authenticatePagePath);
            } else if (!response || response instanceof AxiosError) {
                navigate(Constants.homePagePath);
            } else if (!response.accountCreated) {
                navigate(Constants.createAccountPagePath);
            } else if (response.stripeOnboardingCompleted) {
                navigate(Constants.homePagePath);
            }
        });
        
        /**
         * Appearance options: https://docs.stripe.com/connect/embedded-appearance-options
         */
        return loadConnectAndInitialize({
            publishableKey: Constants.stripePublishableKey,
            fetchClientSecret: fetchClientSecret,
            fonts: StripeConfig.stripeFonts,
            appearance: StripeConfig.stripeAppearanceOptions
        });
    });

    const navigate = useNavigate();
    
    return (
        <PageDefault>
            <DefaultNavbar/>
            <PageContent>
                { showStripeOnboardingElement ? 
                    <>
                        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                            <ConnectAccountOnboarding
                                onExit={() => {
                                    navigate(`${Constants.frontendURL}${Constants.homePagePath}`);
                                }}
                                collectionOptions={{
                                    fields: 'eventually_due',
                                    futureRequirements: 'include'
                                }}
                                // Optional: make sure to follow our policy instructions above
                                // fullTermsOfServiceUrl="{{URL}}"
                                // recipientTermsOfServiceUrl="{{URL}}"
                                // privacyPolicyUrl="{{URL}}"
                                // skipTermsOfServiceCollection={false}
                            />
                        </ConnectComponentsProvider>
                        <NavigationArrowButton
                            onClick={() => setShowStripeOnboardingElement(false)}
                            bottomAnchored='bottom-left'
                            backArrow={true}
                        />
                    </>
                    : 
                    <>                        
                        <div className='connect-to-stripe-icon-wrapper'><FaStripe className='connect-to-stripe-icon'/></div>
                        <div className='connect-to-stripe-title-wrapper'><h1 className='connect-to-stripe-title'>Connect your account with Stripe</h1></div>
                        <div className='connect-to-stripe-text'>We've partnered with Stripe to make sure you get paid on time and to keep your personal and bank details secure.</div>
                        <NavigationArrowButton
                            onClick={() => HelperMethods.signOut()}
                            backArrow={true}
                            bottomAnchored='bottom-left'
                        />
                        <NavigationArrowButton
                            onClick={() => setShowStripeOnboardingElement(true)}
                            bottomAnchored='bottom-right'
                        />
                    </>
                }               
            </PageContent>
        </PageDefault>
    );
}

export default ConnectedAccountPage;

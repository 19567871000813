import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import ApiResponse from '../../classes/ApiResponse';
import AggregatorProfileDisplayData
  from '../../classes/getprofiledisplaydata/AggregatorProfileDisplayData';
import GetProfileDisplayDataRequest
  from '../../classes/getprofiledisplaydata/GetProfileDisplayDataRequest';
import GetProfileDisplayDataResponse
  from '../../classes/getprofiledisplaydata/GetProfileDisplayDataResponse';
import {
  IdentifierType,
} from '../../classes/getprofiledisplaydata/IdentifierType';
import PaymentProfileDisplayData
  from '../../classes/getprofiledisplaydata/PaymentProfileDisplayData';
import ProfileDisplayData
  from '../../classes/getprofiledisplaydata/ProfileDisplayData';
import LoadingIcon from '../../components/LoadingIcon';
import PaymentNavbar from '../../components/navbar/PaymentNavbar';
import PageContent from '../../components/PageContent';
import Constants from '../../configuration/constants';
import HelperMethods from '../../helpers/HelperMethods';
import Logger from '../../helpers/Logger';
import AggregatorPageProfile from './AggregatorPageProfile';
import PaymentPageProfile from './PaymentPageProfile';
import PaymentPageProfileNotFound from './PaymentPageProfileNotFound';

const PaymentPage = (): ReactElement => {
  const { username } = useParams();
  
  const [profileDisplayData, setProfileDisplayData] = useState<ProfileDisplayData | null>(null);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);

  useEffect(() => {
    const fetchProfileDisplayData = async () => {
      try {
        if (!username) {
          setProfileDisplayData(null);
          setLoadingPage(false);
          return;
        }

        const localStorageItem = HelperMethods.getUserProfileDataFromLocalStorage(username);
        if (localStorageItem) {
          setProfileDisplayData(localStorageItem);
          setLoadingPage(false);
          return;
        }

        const response = await ApiResponse.getApiResponse(
          `${Constants.getProfileDisplayDataEndpoint}`,
          new GetProfileDisplayDataRequest(username, IdentifierType.USERNAME),
          GetProfileDisplayDataResponse,
          null,
          null,
          false
        );

        if (!response || response instanceof AxiosError || !response.profileDisplayData) {
          setProfileDisplayData(null);
        } else {
          HelperMethods.putLocalStorageItem(
            `${Constants.localStorangeUserProfileDataKeyPrefix}${response.profileDisplayData.username}`,
            response.profileDisplayData
          );     
          setProfileDisplayData(response.profileDisplayData);
          
          if (response.profileDisplayData instanceof AggregatorProfileDisplayData) {
            response.profileDisplayData.linkedAccountsData.forEach(item => {
              if (item && item.emailAddress && item.profileDisplayData) {
                HelperMethods.putLocalStorageItem(
                  `${Constants.localStorangeUserProfileDataKeyPrefix}${item.profileDisplayData.username}`,
                  item
                ); 
              } 
            })
          }
        }
      } catch (error) {
        Logger.error('Error fetching profileDisplayData: ' + error);
        setProfileDisplayData(null);
      } finally {
        setLoadingPage(false);
      }
    };
    
    fetchProfileDisplayData();
  }, [username]);

  if (loadingPage) {
    return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
  }

  if (profileDisplayData instanceof PaymentProfileDisplayData) {
    return (
      <>
        <PaymentNavbar/>
        <PageContent>
            <PaymentPageProfile
              paymentProfileDisplayData={profileDisplayData}
              isPreview={false}
            />
        </PageContent>
      </>
    );
  } else if (profileDisplayData instanceof AggregatorProfileDisplayData) {
    return (
      <>
        <PaymentNavbar/>
        <PageContent>
          <AggregatorPageProfile
            profileDisplayData={profileDisplayData}
            isPreview={false}
          />
        </PageContent>
      </>
    );
  } else {
    return (
      <>
        <PaymentNavbar/>
        <PageContent>
            <PaymentPageProfileNotFound/>
        </PageContent>
      </>
    );
  }
}

export default PaymentPage;

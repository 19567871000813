import HelperMethods from '../../helpers/HelperMethods';
import { SocialMediaLinks } from '../../types/types';
import ProfileDisplayData from './ProfileDisplayData';

class PaymentProfileDisplayData extends ProfileDisplayData {
    suggestedPayValues: number[];
    thankyouMessage: string;
    socialMediaLinks: SocialMediaLinks;
    stripeConnectedAccountId: string;
    currency: string;

    constructor(
        username: string,
        displayName: string,
        base64ProfileImage: string, 
        qrCodeVerb: string,
        accountType: string,
        suggestedPayValues: number[],
        thankyouMessage: string,
        socialMediaLinks: SocialMediaLinks,
        stripeConnectedAccountId: string,
        currency: string
        ) {
        super(username, displayName, base64ProfileImage, qrCodeVerb, accountType);

        this.stripeConnectedAccountId = stripeConnectedAccountId;
        this.suggestedPayValues = HelperMethods.getValidButtonValues(suggestedPayValues);
        this.thankyouMessage = thankyouMessage;
        this.socialMediaLinks = HelperMethods.getValidSocialMediaLinks(socialMediaLinks);
        this.currency = currency;
    }
}

export default PaymentProfileDisplayData;

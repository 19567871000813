import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';

import ApiResponse from '../../../classes/ApiResponse';
import AccountUserProfileData
  from '../../../classes/getprofilefromtoken/AccountUserProfileData';
import AggregatorAccountUserProfileData
  from '../../../classes/getprofilefromtoken/AggregatorAccountUserProfileData';
import CompanyPaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/CompanyPaymentAccountUserProfileData';
import IndividualPaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/IndividualPaymentAccountUserProfileData';
import PaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import PayoutSchedule
  from '../../../classes/updatepayoutschedule/PayoutSchedule';
import UpdatePayoutScheduleRequest
  from '../../../classes/updatepayoutschedule/UpdatePayoutScheduleRequest';
import UpdatePayoutScheduleResponse
  from '../../../classes/updatepayoutschedule/UpdatePayoutScheduleResponse';
import AggregatorProfileUpdateData
  from '../../../classes/updateprofile/AggregatorProfileUpdateData';
import CompanyPaymentProfileUpdateData
  from '../../../classes/updateprofile/CompanyPaymentProfileUpdateData';
import IndividualPaymentProfileUpdateData
  from '../../../classes/updateprofile/IndividualPaymentProfileUpdateData';
import PaymentProfileUpdateData
  from '../../../classes/updateprofile/PaymentProfileUpdateData';
import ProfileUpdateData
  from '../../../classes/updateprofile/ProfileUpdateData';
import ProfileUpdateRequest
  from '../../../classes/updateprofile/ProfileUpdateRequest';
import UpdateProfileResponse
  from '../../../classes/updateprofile/UpdateProfileResponse';
import PlatformModal from '../../../components/PlatformModal';
import Constants from '../../../configuration/constants';
import HelperMethods from '../../../helpers/HelperMethods';
import Logger from '../../../helpers/Logger';
import { useAppDispatch } from '../../../redux/hooks';
import { updateUserProfileData } from '../../../redux/userDataSlice';
import Base64ProfileImageEdit from './Base64ProfileImageEdit';
import DisplayNameEdit from './DisplayNameEdit';
import LinkedAccountsEdit from './LinkedAccountsEdit';
import PayoutScheduleEdit from './PayoutScheduleEdit';
import QRCodeVerbEdit from './QRCodeVerbEdit';
import SocialMediaLinksEdit from './SocialMediaLinksEdit';
import SuggestedPayValuesEdit from './SuggestedPayValuesEdit';
import ThankyouMessageEdit from './ThankyouMessageEdit';

export type ProfileAttribute = 'suggestedPayValues' 
    | 'displayName'
    | 'base64ProfileImage'
    | 'thankyouMessage'
    | 'socialMediaLinks'
    | 'qrCodeVerb'
    | 'payoutSchedule'
    | 'linkedAccounts';

interface EditProfileModalProps {
    userProfileData: AccountUserProfileData;
    profileAttribute: ProfileAttribute;
    onClose: () => void;
    isVisible: boolean;
}

const EditProfileModal = (props: EditProfileModalProps): ReactElement => {
    const {
        userProfileData,
        profileAttribute, 
        onClose,
        isVisible
    } = props;

    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');
    const [updatingProfile, setUpdatingProfile] = useState<boolean>(false);
    const [updatedUserProfileData, setUpdatedUserProfileData] = useState<AccountUserProfileData>(HelperMethods.cloneWithUpdates(userProfileData, {}));
    const [hasMadeEdit, setHasMadeEdit] = useState<boolean>(false);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        // Updates state to default state if the user closes the modal
        if (!isVisible) {
            setUpdatedUserProfileData(userProfileData);
            setHasMadeEdit(false);
            setCustomerErrorMessage('');
            setUpdatingProfile(false);
        }
    }, [isVisible, userProfileData]);

    const saveChanges = async (): Promise<void> => {
        setCustomerErrorMessage('');
        setUpdatingProfile(true);

        try {
            const response = await ApiResponse.getApiResponse(
                Constants.updateProfileEndpoint,
                getProfileUpdateRequest(updatedUserProfileData),
                UpdateProfileResponse,
                null,
                null,
                true
            );

            // Log the response for debugging (optional)
            console.log('Response:', response);

            // Check for errors and handle navigation
            if (!response || (response instanceof AxiosError && response.request.status === 401)) {
                navigate(Constants.authenticatePagePath);
                return; // Exit the function after navigation
            } else if (response instanceof AxiosError) {
                setCustomerErrorMessage(response.response?.data);
                return; // Exit the function after setting the error message
            } else if (!response.profileUpdateSuccess) {
                navigate(Constants.homePagePath);
                return; // Exit the function after navigation
            }

            // If everything is successful, update the user profile data in Redux
            await dispatch(updateUserProfileData(updatedUserProfileData));
            
            // Close the modal or perform any other actions
            onClose();
        } catch (error) {
            // Handle any unexpected errors
            Logger.error('Failed to save changes: ' + error);
            setCustomerErrorMessage('An error occurred while updating your profile. Please reach out to our team for support.');
        } finally {
            setUpdatingProfile(false); // Ensure this runs regardless of success or failure
        }
    }

    const getProfileUpdateRequest = (updatedUserProfileData: AccountUserProfileData): ProfileUpdateRequest | null => {
        let profileUpdateData: ProfileUpdateData;
        if (updatedUserProfileData instanceof IndividualPaymentAccountUserProfileData) {
            profileUpdateData =  new IndividualPaymentProfileUpdateData(
                updatedUserProfileData.displayName,
                updatedUserProfileData.base64ProfileImage,
                updatedUserProfileData.qrCodeVerb,
                updatedUserProfileData.suggestedPayValues,
                updatedUserProfileData.thankyouMessage,
                updatedUserProfileData.socialMediaLinks,
                updatedUserProfileData.linkedAccountsData.map(item => item.emailAddress)
            );
        } else if (updatedUserProfileData instanceof CompanyPaymentAccountUserProfileData) {
            profileUpdateData = new CompanyPaymentProfileUpdateData(
                updatedUserProfileData.displayName,
                updatedUserProfileData.base64ProfileImage,
                updatedUserProfileData.qrCodeVerb,
                updatedUserProfileData.suggestedPayValues,
                updatedUserProfileData.thankyouMessage,
                updatedUserProfileData.socialMediaLinks,
                updatedUserProfileData.linkedAccountsData.map(item => item.emailAddress)
            );
        } else if (updatedUserProfileData instanceof PaymentAccountUserProfileData) {
            profileUpdateData = new PaymentProfileUpdateData(
                updatedUserProfileData.displayName,
                updatedUserProfileData.base64ProfileImage,
                updatedUserProfileData.qrCodeVerb,
                updatedUserProfileData.suggestedPayValues,
                updatedUserProfileData.thankyouMessage,
                updatedUserProfileData.socialMediaLinks,
                updatedUserProfileData.linkedAccountsData.map(item => item.emailAddress)
            );
        }  else if (updatedUserProfileData instanceof AggregatorAccountUserProfileData) {
            profileUpdateData = new AggregatorProfileUpdateData(
                updatedUserProfileData.displayName,
                updatedUserProfileData.base64ProfileImage,
                updatedUserProfileData.qrCodeVerb,
                updatedUserProfileData.linkedAccountsData.map(item => item.emailAddress)
            );
        } else {
            return null;
        }

        return new ProfileUpdateRequest(profileUpdateData, updatedUserProfileData.accountType);
    }

    const savePayoutScheduleChanges = async (payoutSchedule: PayoutSchedule): Promise<void> => {
        setCustomerErrorMessage('');
        setUpdatingProfile(true);

        await ApiResponse.getApiResponse(
            Constants.updatePayoutScheduleEndpoint,
            new UpdatePayoutScheduleRequest(payoutSchedule),
            UpdatePayoutScheduleResponse,
            null,
            null,
            true
        ).then(response => {
            JSON.stringify(response);
            setUpdatingProfile(false);
            if (!response || (response instanceof AxiosError && response.request.status === 401)) {
                navigate(Constants.authenticatePagePath);
            } else if (response instanceof AxiosError) {
                setCustomerErrorMessage(response.response?.data);
            } else if (!response.profileUpdateSuccess) {
                navigate(Constants.homePagePath);
            } else {
                // Using Redux Dispatch to update the userProfileData in the Redux store
                dispatch(updateUserProfileData(updatedUserProfileData))
                onClose();
            }
        });
    }

    const updateAccountUserProfileData = <T extends AccountUserProfileData>(updatedUserProfileData: T, updatedValues: Partial<T>): void => {
        const updatedData: T = HelperMethods.cloneWithUpdates(updatedUserProfileData, updatedValues);

        setUpdatedUserProfileData(updatedData);
    }

    const renderContent = () => {
        const commonModalProps = {
            onClose,
            isVisible,
            actionVerb: 'Save',
            actionButtonDisabled: !hasMadeEdit,
            actionInProgress: updatingProfile,
            customerErrorMessage,
        };

        switch (profileAttribute) {
            case 'suggestedPayValues':
                return ( updatedUserProfileData instanceof PaymentAccountUserProfileData ?
                    <PlatformModal
                        {...commonModalProps}
                        actionFunction={saveChanges}
                        modalTitle='Suggested Values'
                        modalDescription={`Add up to ${Constants.maxPaySelectionButtons} suggested payment amounts (min ${HelperMethods.getCurrencySymbolFromString(updatedUserProfileData.currency)}${Constants.minimumTransactionAmount.toFixed(2)}) to appear on your profile`}
                        modalBody={
                            <SuggestedPayValuesEdit
                                updatedUserProfileData={updatedUserProfileData}
                                updateAccountUserProfileData={updateAccountUserProfileData}
                                setHasMadeEdit={setHasMadeEdit}
                            />
                        }
                    />
                    :
                    <></>
                );
            case 'base64ProfileImage':
                return <PlatformModal
                    {...commonModalProps}
                    actionFunction={saveChanges}
                    modalTitle='Profile Image'
                    modalDescription='Upload a photo or logo to be displayed on your profile'
                    modalBody={
                        <Base64ProfileImageEdit
                            updatedUserProfileData={updatedUserProfileData}
                            updateAccountUserProfileData={updateAccountUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                        />
                    }
                />;
            case 'displayName':
                return <PlatformModal  
                    {...commonModalProps}
                    actionFunction={saveChanges}
                    modalTitle='Display Name'
                    modalDescription='Your first name, stage name or the operating name of your business to be displayed on your profile'
                    modalBody={
                        <DisplayNameEdit
                            updatedUserProfileData={updatedUserProfileData}
                            updateAccountUserProfileData={updateAccountUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                        />
                    }
                />;
            case 'linkedAccounts':
                return <PlatformModal
                    {...commonModalProps}
                    actionFunction={saveChanges}
                    modalTitle='Linked Accounts'
                    modalDescription={ updatedUserProfileData instanceof AggregatorAccountUserProfileData ?
                        'To add a Linked Account enter the email address associated with the account'
                        :
                        'If you no longer want to be linked with an account you can remove it from the list below'
                    }
                    modalBody={
                        <LinkedAccountsEdit
                            updatedUserProfileData={updatedUserProfileData}
                            updateAccountUserProfileData={updateAccountUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                            setCustomerErrorMessage={setCustomerErrorMessage}
                        />
                    }
                />;
            case 'thankyouMessage':
                return (updatedUserProfileData instanceof PaymentAccountUserProfileData ?
                    <PlatformModal
                        {...commonModalProps}
                        actionFunction={saveChanges}
                        modalTitle='Personal Message'
                        modalDescription='A chance to thank those who support you'
                        modalBody={
                            <ThankyouMessageEdit
                                updatedUserProfileData={updatedUserProfileData}
                                updateAccountUserProfileData={updateAccountUserProfileData}
                                setHasMadeEdit={setHasMadeEdit}
                            />
                        }
                    />
                    :
                    <></>
                );
            case 'socialMediaLinks':
                return (updatedUserProfileData instanceof PaymentAccountUserProfileData ?
                    <PlatformModal
                        {...commonModalProps}
                        actionFunction={saveChanges}
                        modalTitle='Social Media'
                        modalDescription='Increase your following by sharing your social media links'
                        modalBody={
                            <SocialMediaLinksEdit
                                updatedUserProfileData={updatedUserProfileData}
                                updateAccountUserProfileData={updateAccountUserProfileData}
                                setHasMadeEdit={setHasMadeEdit}
                            />
                        }
                    />
                    :
                    <></>
                );
            case 'payoutSchedule':
                return (updatedUserProfileData instanceof PaymentAccountUserProfileData ?
                    <PlatformModal
                        {...commonModalProps}
                        actionFunction={ () => savePayoutScheduleChanges(updatedUserProfileData.payoutSchedule)}
                        modalTitle='Payout Schedule'
                        modalDescription='Choose when you want to be paid'
                        modalBody={
                            <PayoutScheduleEdit
                                updatedUserProfileData={updatedUserProfileData}
                                updateAccountUserProfileData={updateAccountUserProfileData}
                                setHasMadeEdit={setHasMadeEdit}
                            />
                        }
                    />
                    :
                    <></>
                );
            case 'qrCodeVerb':
                return <PlatformModal
                    {...commonModalProps}
                    actionFunction={saveChanges}
                    modalTitle='QR Code Verb'
                    modalDescription='Adding an action verb can increase how many people interract with your QR Code'
                    modalBody={
                        <QRCodeVerbEdit
                            updatedUserProfileData={updatedUserProfileData}
                            updateAccountUserProfileData={updateAccountUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                        />
                    }
                />;
            default:
                return <></>;
        }
    };

    return (
        renderContent()
    );
}

export default EditProfileModal;

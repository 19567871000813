import { IdentifierType } from './IdentifierType';

class GetProfileDisplayDataRequest {
  static type: string = 'GetProfileDisplayDataRequest';
  
  identifier: string;
  identifierType: string;

  constructor(identifier: string, identifierType: IdentifierType) {
    this.identifier = identifier;
    this.identifierType = identifierType.value;
  }
}

export default GetProfileDisplayDataRequest;

class InitiateAuthRequest {
    static type: string = 'InitiateAuthRequest';

    emailAddress: string;
      
    constructor(emailAddress: string) {
        this.emailAddress = emailAddress;
    }
}

export default InitiateAuthRequest;

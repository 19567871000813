import './signedinnavbar.scss';

import React, {
  ReactElement,
  useEffect,
} from 'react';

import {
  GoHome,
  GoHomeFill,
} from 'react-icons/go';
import {
  IoQrCode,
  IoQrCodeOutline,
} from 'react-icons/io5';
import {
  PiPiggyBankFill,
  PiPiggyBankLight,
} from 'react-icons/pi';
import { useNavigate } from 'react-router';

import AccountUserProfileData
  from '../../classes/getprofilefromtoken/AccountUserProfileData';
import Constants from '../../configuration/constants';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import DefaultNavbar from './DefaultNavbar';

interface SignedInPaymentNavbarProps {
    pageIndex: number;
    setPageIndex: (index: number) => void;
}

const SignedInPaymentNavbar = (props: SignedInPaymentNavbarProps): ReactElement => {
  const { pageIndex, setPageIndex } = props;

  const navigate = useNavigate();
  
  const reduxUserProfileData: AccountUserProfileData | null = useAppSelector((state: RootState) => state.userData.userProfileData);
  
  useEffect(() => {
    if (!reduxUserProfileData) {
      navigate(Constants.homePagePath);
    }
  }, [reduxUserProfileData, navigate]);
  
  if (!reduxUserProfileData) {
    return <DefaultNavbar/>;
  }

  return (
    <div className='signed-in-navbar'>
      <div className='signed-in-central-navbar'>
        { pageIndex === 0 ? 
          <GoHomeFill className='signed-in-navbar-icon'/>
          :
          <GoHome className='signed-in-navbar-icon' onClick={() => setPageIndex(0)}/>
        }
        { pageIndex === 1 ?
          <img className='signed-in-navbar-icon profile-image selected' src={reduxUserProfileData.base64ProfileImage}/>
          :
          <img className='signed-in-navbar-icon profile-image' src={reduxUserProfileData.base64ProfileImage} onClick={() => setPageIndex(1)}/>
        }
        <div className='signed-in-navbar-logo-wrapper'>
            <img className='navbar-logoo' onClick={() => navigate(Constants.homePagePath)} src={require(`../../assets/images/logos/${Constants.secondaryLogoWhite}`)} alt='logo'/>
        </div>
        { pageIndex === 2 ?
          <PiPiggyBankFill className='signed-in-navbar-icon'/>
          :
          <PiPiggyBankLight className='signed-in-navbar-icon' onClick={() => setPageIndex(2)}/>
        }
        { pageIndex === 3 ?
          <IoQrCode className='signed-in-navbar-icon'/>
          :
          <IoQrCodeOutline className='signed-in-navbar-icon' onClick={() => setPageIndex(3)}/>
        }
      </div>
    </div>
  );
};

export default SignedInPaymentNavbar;

import {
  PayoutScheduleInterval,
  PayoutScheduleWeeklyAnchor,
} from '../classes/updatepayoutschedule/PayoutSchedule';
import { dynamicConfiguration } from './configuration';
import { Currency } from './types';

/**
 * This Constants object should contain any business or product related constants.
 */
class Constants {
    // Application Business/Product constants
    static minimumTransactionAmount: number = 2;
    static maximumTransactionAmount: number = 9999.99;
    static maxPaySelectionButtons: number = 3;
    static platformFeeMultiplier: number = 0.05;
    static platformFeeConstant: number = 0;
    static paymentProcessingFeeMultiplier: number = 0.025;
    static paymentProcessingFeeConstant: number = 0.2;
    static otpLength: number = 6;
    static qrCodeVerbs: string[] = ['DONATE', 'SUPPORT', 'TIP', 'NONE'];
    static validCurrencies: Currency[] = Currency.getAllCurrencies();
    static validPayoutScheduleIntervals: PayoutScheduleInterval[] = ['daily', 'weekly', 'monthly'];
    static validPayoutScheduleWeeklyAnchors: PayoutScheduleWeeklyAnchor[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

    // Base URLs
    static frontendURL: string = dynamicConfiguration.frontendURL;
    static backendURL: string = dynamicConfiguration.backendURL;

    // Backend endpoints
    static getProfileDisplayDataEndpoint: string = 'get-profile-display-data';
    static getStripePaymentIntentEndpoint: string = 'get-stripe-payment-intent';
    static createAccountEndpoint: string = 'create-account';
    static updateProfileEndpoint: string = 'update-profile';
    static confirmEmailAddressEndpoint: string = 'confirm-email-address';
    static authChallengeResponseEndpoint: string = 'auth-challenge-response';
    static initiateAuthRequestEndpoint: string = 'initiate-auth-request';
    static getProfileFromTokenEndpoint: string = 'get-profile-from-token';
    static getAccountStateFromTokenEndpoint: string = 'get-account-state-from-token';
    static createStripeAccountSessionEndpoint: string = 'create-stripe-account-session';
    static sendQRCodeByEmailEndpoint: string = 'send-qr-code-by-email';
    static sendCustomerContactMessageEndpoint: string = 'send-customer-contact-email';
    static updatePayoutScheduleEndpoint: string = 'update-payout-schedule';

    // Frontend page paths
    static homePagePath: string = '/';
    static paymentPagePath: string = '/p';
    static paymentConfirmationPagePath: string = '/payment-confirmation';
    static authenticatePagePath: string = '/authenticate';
    static createAccountPagePath: string = '/create-account';
    static connectedAccountPagePath: string = '/connected-account';
    static taxOnTipsPagePath: string = '/tax-on-tips';
    static tippingTipsPagePath: string = '/tipping-tips';
    static aboutPagePath: string = '/about';
    static contactPagePath: string = '/contact';
    static faqsPagePath: string = '/faqs';
    static privacyPolicyPagePath: string = '/privacy-policy';
    static cookiePolicyPagePath: string = '/cookie-policy';
    static acceptableUsePolicyPagePath: string = '/acceptable-use-policy';
    static refundPolicyPagePath: string = '/refund-policy';
    static disclaimerPolicyPagePath: string = '/disclaimer-policy';
    static termsAndConditionsPolicyPagePath: string = '/terms-and-conditions-policy'
    static allocationOfTipsActPagePath: string = '/allocation-of-tips-act'

    // Business strings
    static registeredCompanyName: string = 'Omnia Technology Ltd';
    static registeredCompanyAddress: string = '167-169 Great Portland Street, 5th Floor, London, United Kingdom, W1W 5PF';
    static registeredCompanyNumber: string = '16126993';
    static tradingName: string = 'Omnia'
    static tradingNameForKey: string = 'omnia';

    // Asset files          
    static primaryLogoBlack: string = 'omnia-name-logo-black.png';
    static primaryLogoWhite: string = 'omnia-name-logo-white.png';
    static secondaryLogoWhite: string = 'omnia-logo-black-background.png';
    static qrCodeLogo: string = 'omnia-logo-black-background-small-rounded.png';

    // Cookies
    static jwtTokenName: string = `${Constants.tradingNameForKey}-id-token`;
    static authSessionIdTokenName: string = `${Constants.tradingNameForKey}-auth-session-id`;

    // Local Storage
    static maxLocalStorageAgeInMilis: number = 5 * 60 * 1000;
    static localStorangeUserProfileDataKeyPrefix: string = `${Constants.tradingNameForKey}_user_profile_data_`;

    // Public keys
    static stripePublishableKey: string = dynamicConfiguration.stripePublishableKey;
    static googleReCaptchaSiteKey: string = '6LfjaX4qAAAAAGCExXHhLAoBLGXZ4IYajfwXSkm9';

    // Regex Patterns
    static regexPatternEmailAddress: RegExp = /^[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
}

export default Constants;

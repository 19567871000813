import './paymentconfirmationpageviewedittab.scss';

import { ReactElement } from 'react';

import PaymentAccountUserProfileData
  from '../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import HelperMethods from '../../helpers/HelperMethods';
import PaymentConfirmationPageProfile
  from '../paymentconfirmation/PaymentConfirmationPageProfile';

interface PaymentConfirmationPageViewEditTabProps {
  userProfileData: PaymentAccountUserProfileData;
}

const PaymentConfirmationPageViewEditTab = (props: PaymentConfirmationPageViewEditTabProps): ReactElement => {
  const { userProfileData } = props;

  return (
    <div className='thankyou-profile-preview-wrapper'>
      <div className='thankyou-profile-preview'>
        <PaymentConfirmationPageProfile
          paymentProfileDisplayData={HelperMethods.getPaymentProfileDisplayDataFromPaymentAccountUserProfileData(userProfileData)}
          userProfileData={userProfileData}
          isPreview={true}
        />
      </div>
    </div>
  );
}

export default PaymentConfirmationPageViewEditTab;

class GetStripePaymentIntentResponse {
  static type: string = 'GetStripePaymentIntentResponse';

  clientSecret: string;
  
  constructor(data: GetStripePaymentIntentResponse) {
    this.clientSecret = data.clientSecret;
  }
}

export default GetStripePaymentIntentResponse;

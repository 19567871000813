
class Logger {
    public static log(str: any): void {
        if (process.env.REACT_APP_PLATFORM_ENV === 'dev') {
            console.log(str);
        }
    }

    public static error(str: any): void {
        if (process.env.REACT_APP_PLATFORM_ENV === 'dev') {
            console.error(str);
        }
    }
}

export default Logger;

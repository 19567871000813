import './vieweditprofilepage.scss';

import { ReactElement } from 'react';

import AggregatorAccountUserProfileData
  from '../../classes/getprofilefromtoken/AggregatorAccountUserProfileData';
import HelperMethods from '../../helpers/HelperMethods';
import AggregatorPageProfile from '../payment/AggregatorPageProfile';

interface ViewEditProfileTabItem {
    name: string;
    element: JSX.Element;
};

interface AggregatorViewEditProfilePageProps { 
    userProfileData: AggregatorAccountUserProfileData;
}

const AggregatorViewEditProfilePage = (props: AggregatorViewEditProfilePageProps): ReactElement => {
    const { userProfileData } = props;

    return (
        <div className='view-edit-profile-page-wrapper'>
            <p className='view-edit-profile-page-message'>View & Edit your Profile here</p>            
            <div className='payment-page-preview'>
                <AggregatorPageProfile
                    profileDisplayData={HelperMethods.getAggregatorProfileDisplayDataFromAggregatorAccountUserProfileData(userProfileData)}
                    userProfileData={userProfileData}
                    isPreview={true}
                />
            </div>
        </div>
    );
}

export default AggregatorViewEditProfilePage;

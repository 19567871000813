import './paymentaccountdashboardpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import dayjs from 'dayjs';
import {
  FaCircleCheck,
  FaClock,
  FaTruck,
} from 'react-icons/fa6';

import PaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import PayoutSchedule
  from '../../../classes/updatepayoutschedule/PayoutSchedule';
import HelperMethods from '../../../helpers/HelperMethods';
import EditProfileModal
  from '../../vieweditprofile/editprofilemodal/EditProfileModal';

interface PaymentsPageProps {
    userProfileData: PaymentAccountUserProfileData;
}

const PaymentsPage = (props: PaymentsPageProps): ReactElement => {
    const { userProfileData } = props;

    const [editPayoutScheduleModalVisible, setEditPayoutScheduleModalVisible] = useState<boolean>(false);

    // Pagination Logic
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10; // Number of payments to display per page
    const totalPages = Math.ceil(userProfileData.payouts.length / itemsPerPage);

    const indexOfLastPayout = currentPage * itemsPerPage;
    const indexOfFirstPayout = indexOfLastPayout - itemsPerPage;

    const currentPayouts = userProfileData.payouts.slice(indexOfFirstPayout, indexOfLastPayout);

    // Handle page change
    const handlePageChange = (pageNumber: number): void => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const getPayoutScheduleText = (payoutSchedule: PayoutSchedule): string => {
        const { interval, weeklyAnchor, monthlyAnchor } = payoutSchedule;
    
        // Helper function to format the anchor
        const formatAnchor = (anchor: string | number | null): string => {
            if (!anchor) return '';
    
            // For weekly, the anchor is a day of the week (string)
            if (typeof anchor === 'string') {
                return `on <b>${HelperMethods.capitalizeFirstLetter(anchor)}</b>`;
            }

            return `on <b>${HelperMethods.getOrdinalSuffix(anchor)}</b>`;
        };
    
        switch (interval) {
            case 'daily':
                return `Your available funds are automatically paid out <b>Daily</b>`;
            case 'weekly':
                return `Your available funds are automatically paid out <b>Weekly</b> ${formatAnchor(weeklyAnchor)}`;
            case 'monthly':
                return `Your available funds are automatically paid out <b>Monthly</b> ${formatAnchor(monthlyAnchor)}`;
            default:
                return 'Your available funds are paid out automatically';
        }
    };

    const onClose = (): void => {
        setEditPayoutScheduleModalVisible(false);
    }
    
    return (
        <div className='transaction-list'>
            { currentPage === 1 &&
                <>
                    <div className='payout-schedule-section'>
                        <p className='payout-schedule-text' dangerouslySetInnerHTML={{ __html: getPayoutScheduleText(userProfileData.payoutSchedule) }}></p>
                        <button className='edit-payout-schedule-button' onClick={() => setEditPayoutScheduleModalVisible(true)} color='black'>Edit</button>
                    </div>
                </>
            }
            {currentPayouts.map((payout, index) => (
                <div key={index} className={`transaction-item ${index === currentPayouts.length - 1 ? 'last' : ''}`}>
                    <div className='transaction-status-section'>
                        {(() => {
                            switch (payout.status) {
                                case 'paid':
                                    return <FaCircleCheck className='transaction-icon paid' />;
                                case 'pending':
                                    return <FaClock className='transaction-icon pending' />;
                                case 'in_transit':
                                    return <FaTruck className='transaction-icon in-transit' />;
                                default:
                                    return <span className='transaction-unknown-status'>Unknown Status</span>;
                            }
                        })()}
                    </div>
                    <div className='transaction-details-section'>
                        <p className='transaction-amount'><b>{`${HelperMethods.getCurrencySymbolFromString(payout.currency)}${payout.amount.toFixed(2)}`}</b></p>
                        <p className='transaction-date'>{`on ${dayjs(payout.created*1000).format('DD MMM, HH:mm')}`}</p>
                    </div>
                </div>
            ))}
            { totalPages > 1 &&
                <div className='transaction-pagination-controls'>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`transaction-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            }
            { editPayoutScheduleModalVisible &&
                <EditProfileModal
                    userProfileData={userProfileData}
                    profileAttribute='payoutSchedule'
                    onClose={onClose}
                    isVisible={editPayoutScheduleModalVisible}
                />
            }
        </div>
    );
}

export default PaymentsPage;

import { useState } from 'react';

import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import { ConnectComponentsProvider } from '@stripe/react-connect-js';

import ApiResponse from '../../classes/ApiResponse';
import CreateStripeAccountSessionResponse
  from '../../classes/createstripeaccountsession/CreateStripeAccountSessionResponse';
import PaymentAccountUserProfileData
  from '../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import SignedInPaymentNavbar
  from '../../components/navbar/SignedInPaymentNavbar';
import PageContent from '../../components/PageContent';
import PageDefault from '../../components/PageDefault';
import Constants from '../../configuration/constants';
import StripeConfig from '../../configuration/stripeConfig';
import PaymentAccountDashboardPage
  from '../dashboard/paymentaccount/PaymentAccountDashboardPage';
import QRCodePage from '../qrcode/QRCodePage';
import SignedInProfilePage from '../signedinprofilepage/SignedInProfilePage';
import PaymentAccountSignedInHomePage
  from '../singedinhome/PaymentSignedInHomePage';

interface SignedInPageInterface {
  element: JSX.Element;
}

interface PaymentSignedInPageProps {
  userProfileData: PaymentAccountUserProfileData;
}

const PaymentSignedInPage = (props: PaymentSignedInPageProps) => {
  const { userProfileData } = props;
  
  const [pageIndex, setPageIndex] = useState<number>(0);

  const [stripeConnectInstance] = useState<StripeConnectInstance>(() => {

    const fetchClientSecret = async (): Promise<string> => {
      return await ApiResponse.getApiResponse(
        Constants.createStripeAccountSessionEndpoint,
        null,
        CreateStripeAccountSessionResponse, 
        null,
        null,
        true
      ).then(response => {
        return response ? response.clientSecret : '';
      });
    };
        
    /**
     * Appearance options: https://docs.stripe.com/connect/embedded-appearance-options
     */
    return loadConnectAndInitialize({
      publishableKey: Constants.stripePublishableKey,
      fetchClientSecret: fetchClientSecret,
      fonts: StripeConfig.stripeFonts,
      appearance: StripeConfig.stripeAppearanceOptions
    });
  });

  const signedInPages: SignedInPageInterface[] = [
    { element: <PaymentAccountSignedInHomePage userProfileData={userProfileData}/> },
    { element: <SignedInProfilePage/> },
    { element: <PaymentAccountDashboardPage userProfileData={userProfileData}/> },
    { element: <QRCodePage/> }
  ];

  return (
    <PageDefault>
      <SignedInPaymentNavbar pageIndex={pageIndex} setPageIndex={setPageIndex}/>
      <PageContent>
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          {signedInPages[pageIndex].element}
        </ConnectComponentsProvider>
      </PageContent>
    </PageDefault>
  );
}

export default PaymentSignedInPage;

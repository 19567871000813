import './profilepageprofile.scss';

import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useState,
} from 'react';

import { FaCircleArrowRight } from 'react-icons/fa6';
import { TfiPencil } from 'react-icons/tfi';
import { useNavigate } from 'react-router';

import AggregatorProfileDisplayData
  from '../../classes/getprofiledisplaydata/AggregatorProfileDisplayData';
import ProfileDisplayData
  from '../../classes/getprofiledisplaydata/ProfileDisplayData';
import AggregatorAccountUserProfileData
  from '../../classes/getprofilefromtoken/AggregatorAccountUserProfileData';
import LinkedAccountData
  from '../../classes/getprofilefromtoken/LinkedAccountData';
import Constants from '../../configuration/constants';
import EditProfileModal, {
  ProfileAttribute,
} from '../vieweditprofile/editprofilemodal/EditProfileModal';

interface LinkedAccountPreviewProps {
  isEdit: boolean;
  profileDisplayData: ProfileDisplayData;
}

export const LinkedAccountPreview = (props: LinkedAccountPreviewProps): ReactElement => {
  const { isEdit, profileDisplayData } = props;

  return (
    <div className='aggregated-account-card-profile-section'>
      <div className={`aggregated-account-profile-image-wrapper ${isEdit ? 'edit' : ''}`}>
        <img className='aggregated-account-profile-image' src={profileDisplayData.base64ProfileImage} alt='img'/>
      </div>
      <p className='aggregated-account-display-name'>{profileDisplayData.displayName}</p>
    </div>
  );
}

interface LinkedAccountProfileCardListProps {
  linkedAccountData: LinkedAccountData;
}

interface LinkedProfileCardProps {
  index: number;
  isPreview: boolean;
  isEdit: boolean;
  profileDisplayData: ProfileDisplayData;
}

export const LinkedProfileCard = (props: LinkedProfileCardProps): ReactElement => {
  const { index, isPreview, isEdit, profileDisplayData } = props;

  const navigate = useNavigate();

  return (
    <button
      key={index}
      className={`aggregated-account-card-button ${isPreview ? 'disabled' : ''}`}
      onClick={() => navigate(`${Constants.paymentPagePath}/${profileDisplayData.username}`)}
    >
      <LinkedAccountPreview isEdit profileDisplayData={profileDisplayData}/>
      { !isEdit && <FaCircleArrowRight className='aggregated-account-card-arrow'/> }
    </button>
  );
}

interface AggregatorPageProfileProps {
  profileDisplayData: AggregatorProfileDisplayData;
  userProfileData?: AggregatorAccountUserProfileData;
  isPreview: boolean;
}

const AggregatorPageProfile = (props: AggregatorPageProfileProps): ReactElement => {
  const { profileDisplayData, userProfileData, isPreview } = props;

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [profileAttribute, setProfileAttribute] = useState<ProfileAttribute>('base64ProfileImage');

  const toggleEditModal = (isModalVisible: boolean, setIsModalVisibleState: Dispatch<SetStateAction<boolean>>, profileAttribute: ProfileAttribute): void => {
    setProfileAttribute(profileAttribute);
    setIsModalVisibleState(!isModalVisible);
  }

    return (
        <div className='payment-page-centre-content'>
            { profileDisplayData.base64ProfileImage ?
                <div className='payment-page-profile-image-wrapper'>
                    { isPreview &&
                        <div className='edit-profile-image-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'base64ProfileImage')}>
                        <TfiPencil className='edit-profile-pencil-icon'/> 
                        </div>
                    }
                    <img className='payment-page-profile-image' src={profileDisplayData.base64ProfileImage} alt='img'/>
                </div>
                :
                <div className='margin-top'/>
            }
            { profileDisplayData.displayName &&
                <div className='profile-name-wrapper'>
                  { isPreview &&
                      <div className='edit-profile-name-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'displayName')}>
                      <TfiPencil className='edit-profile-pencil-icon'/> 
                      </div>
                  }
                <div className='payment-page-profile-name'>{profileDisplayData.displayName}</div>
                </div>
            }
            { profileDisplayData.linkedAccountsData && profileDisplayData.linkedAccountsData.length > 0 &&
              <>
                <div className='aggregator-page-profile-cta'>Select who you would like to tip:</div>
                <div className='aggregated-accounts-section'>
                  { isPreview &&
                    <div className='edit-aggregated-accounts-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'linkedAccounts')}>
                      <TfiPencil className='edit-profile-pencil-icon'/> 
                    </div>
                  }
                    { profileDisplayData.linkedAccountsData.map((item, index) => (
                        <LinkedProfileCard index={index} isPreview={isPreview} isEdit={false} profileDisplayData={item.profileDisplayData}/>
                    ))}
                </div>
              </>
            }
            { isPreview && (!profileDisplayData.linkedAccountsData || profileDisplayData.linkedAccountsData.length === 0) &&
                <div className='add-profile-content-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'linkedAccounts')}>
                    Add Linked Accounts
                </div>
            }
            { isPreview && userProfileData &&
                <EditProfileModal
                    userProfileData={userProfileData}
                    profileAttribute={profileAttribute}
                    onClose={() => toggleEditModal(editModalVisible, setEditModalVisible, profileAttribute)}
                    isVisible={editModalVisible}
                />
            }
      </div>
    );
}

export default AggregatorPageProfile;

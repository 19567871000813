import './displaynameedit.scss';

import {
  ChangeEvent,
  ReactElement,
} from 'react';

import AccountUserProfileData
  from '../../../classes/getprofilefromtoken/AccountUserProfileData';
import { EditAccountProfileModalBodyProps } from './types';

const DisplayNameEdit = (props: EditAccountProfileModalBodyProps<AccountUserProfileData>): ReactElement => {
  const { updatedUserProfileData, updateAccountUserProfileData, setHasMadeEdit } = props;

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setHasMadeEdit(true);
    const newDisplayName = event.target.value;
    
    updateAccountUserProfileData(updatedUserProfileData, { displayName: newDisplayName });
  }
  
  return (
    <input 
      className='display-name-text-input'
      type='name'
      placeholder='Display Name'
      spellCheck={false}
      value={updatedUserProfileData.displayName}
      onChange={onChange}
    />
  );
};

export default DisplayNameEdit;

import './accountqrcode.scss';

import {
  forwardRef,
  ReactElement,
} from 'react';

import { QRCodeSVG } from 'qrcode.react';

import AccountUserProfileData
  from '../../classes/getprofilefromtoken/AccountUserProfileData';
import Constants from '../../configuration/constants';

type SizeType = 'preview' | 'full-size-modal' | 'email-and-download';

interface AccountQRCodeProps {
    userProfileData: AccountUserProfileData;
    sizeType: SizeType;
}

const AccountQRCode = forwardRef<HTMLDivElement, AccountQRCodeProps>((props: AccountQRCodeProps, ref): ReactElement => {
    const { userProfileData, sizeType } = props;

    const calculateSize = (sizeType: SizeType): number => {
        switch (sizeType) {
            case 'preview':
                return 175;
            case 'full-size-modal':
                return 300;
            case 'email-and-download':
                return 750;
        }
    }

    const size = calculateSize(sizeType);

    return (
        <div ref={ref} className={`qr-code-container ${sizeType}`}>
            { userProfileData.qrCodeVerb && userProfileData.qrCodeVerb !== 'NONE' &&
                <h2 className={'qr-code-verb-text' + ' ' + sizeType}>{userProfileData.qrCodeVerb}</h2>
            }
            <QRCodeSVG
                value={`${Constants.frontendURL}${Constants.paymentPagePath}/${userProfileData.username}`}
                size={size}
                level='Q'
                includeMargin={false}
                imageSettings={{
                    src: require(`../../assets/images/logos/${Constants.qrCodeLogo}`),
                    height: size/3.5,
                    width: size/3.5,
                    excavate: true
                }}
            />
            {/* <div className={'powered-by-message' + ' ' +  sizeType}>
                <p className={'powered-by-text' + ' ' + sizeType}>Powered by</p>
                <img
                    className={'qr-code-logo' + ' ' + sizeType}
                    src={require(`../../assets/images/logos/${Constants.primaryLogoBlack}`)} alt={Constants.tradingName}
                />
            </div> */}
            {/* <div className={`payment-type-section ${sizeType}`}>
                <FaGooglePay className={`payment-type-icon ${sizeType}`}/>
                <FaCcPaypal className={`payment-type-icon ${sizeType}`}/>
                <FaApplePay className={`payment-type-icon ${sizeType}`}/>
            </div> */}
        </div>
    );
});

export default AccountQRCode;

import './qrcodepage.scss';

import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useState,
} from 'react';

import { domToPng } from 'modern-screenshot';
import { renderToString } from 'react-dom/server';
import { useNavigate } from 'react-router';

import AccountUserProfileData
  from '../../classes/getprofilefromtoken/AccountUserProfileData';
import PlatformModal from '../../components/PlatformModal';
import Constants from '../../configuration/constants';
import Logger from '../../helpers/Logger';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import EditProfileModal
  from '../vieweditprofile/editprofilemodal/EditProfileModal';
import AccountQRCode from './AccountQRCode';
import DownloadQRCodeModal from './DownloadQRCodeModal';
import EmailQRCodeModal from './EmailQRCodeModal';

const QRCodePage = (): ReactElement => {
  const [viewQRCodeModalVisible, setViewQRCodeModalVisible] = useState<boolean>(false);
  const [emailQRCodeModalVisible, setEmailQRCodeModalVisible] = useState<boolean>(false);
  const [downloadQRCodeModalVisible, setDownloadQRCodeModalVisible] = useState<boolean>(false);
  const [editQRCodeVerbModalVisible, setEditQRCodeVerbModalVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  const reduxUserProfileData: AccountUserProfileData | null = useAppSelector((state: RootState) => state.userData.userProfileData);

  const toggleModal = (isModalVisible: boolean, setIsModalVisibleState: Dispatch<SetStateAction<boolean>>): void => {
    setIsModalVisibleState(!isModalVisible);
  }

  const createQRCodeHTMLElement = async (): Promise<HTMLElement | null> => {
    // Render the React component to an HTML string
    if (!reduxUserProfileData) {
      return null;
    }

    const htmlString = renderToString(<AccountQRCode userProfileData={reduxUserProfileData} sizeType={'email-and-download'}/>);
    
    // Create a hidden container
    const hiddenContainer = document.createElement('div');
    hiddenContainer.style.position = 'absolute';
    hiddenContainer.style.left = '-9999px';
    hiddenContainer.style.top = '-9999px';
    hiddenContainer.innerHTML = htmlString;
    document.body.appendChild(hiddenContainer);

    // Find the element you want to convert
    const element: HTMLElement = hiddenContainer.querySelector('.qr-code-container') as HTMLElement;
    if (element) {
        // Ensure element is visible and fully rendered
        //await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
        await waitForImagesToLoad(element);
        return element;
    } else {
        return null;
    }
  }

  const waitForImagesToLoad = async (element: HTMLElement): Promise<void> => {
    const images = Array.from(element.querySelectorAll('img'));
    await Promise.all(images.map(img => {
      return new Promise<void>((resolve, reject) => {
        if (img.complete && img.naturalHeight !== 0) {
          resolve(); // Image is already loaded
        } else {
          img.onload = () => resolve();
          img.onerror = reject; // Handle image loading errors
        }
      });
    }));
    return;
  }

  const buildPngFromHTMLElement = async (element: HTMLElement): Promise<string> => {

    // The original implementation. It has better resolution but wouldn't always load images on Safari
    // try {
    //   await new Promise(resolve => setTimeout(resolve, 500)); // Wait for 0.5 seconds
    //   const dataUrl = await toJpeg(element, { cacheBust: true, pixelRatio: 2 });
    //   return dataUrl;
    // } catch (error) {
    //   console.error('Error generating image:', error);
    //   throw error;
    // }

    // This implementation doesn't render images in as good quality but is more reliable
    try {
      // Optionally, wait for a short period to ensure the element is fully rendered
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Convert the HTML element to a PNG data URL
      const dataUrl = await domToPng(element);

      return dataUrl;
    } catch (error) {
        Logger.error('Error generating image: ' + error);
        throw error;
    }
  };

  if (!reduxUserProfileData) {
    navigate(Constants.homePagePath);
    return <></>;
  }

  return (
    <>
      <h1 className='qr-code-page-title'>Your QR Code</h1>
      <p className='qr-code-page-text'>This is your custom QR code, created from your unique username</p>
      <AccountQRCode userProfileData={reduxUserProfileData} sizeType={'preview'}/>
      <div className='qr-code-page-action-buttons'>
        <div className='qr-code-page-action-button' onClick={() => toggleModal(viewQRCodeModalVisible, setViewQRCodeModalVisible)}>Full-screen</div>
        <PlatformModal
          modalBody={<AccountQRCode userProfileData={reduxUserProfileData} sizeType={'full-size-modal'}/>}
          onClose={() => toggleModal(viewQRCodeModalVisible, setViewQRCodeModalVisible)}
          isVisible={viewQRCodeModalVisible}
        />
        <div className='qr-code-page-action-button email' onClick={() => toggleModal(emailQRCodeModalVisible, setEmailQRCodeModalVisible)}>Email QR Code</div>
        <EmailQRCodeModal
          onClose={() => toggleModal(emailQRCodeModalVisible, setEmailQRCodeModalVisible)}
          createQRCodeHTMLElement={createQRCodeHTMLElement}
          buildPngFromHTMLElement={buildPngFromHTMLElement}
          isVisible={emailQRCodeModalVisible}
        />
        <div className='qr-code-page-action-button email' onClick={() => toggleModal(downloadQRCodeModalVisible, setDownloadQRCodeModalVisible)}>Download QR Code</div>
        <DownloadQRCodeModal
          onClose={() => toggleModal(downloadQRCodeModalVisible, setDownloadQRCodeModalVisible)}
          createQRCodeHTMLElement={createQRCodeHTMLElement}
          buildPngFromHTMLElement={buildPngFromHTMLElement}
          isVisible={downloadQRCodeModalVisible}
        />
        <div className='qr-code-page-action-button edit' onClick={() => toggleModal(editQRCodeVerbModalVisible, setEditQRCodeVerbModalVisible)}>Edit Verb</div>
        <EditProfileModal
          userProfileData={reduxUserProfileData}
          profileAttribute='qrCodeVerb'
          onClose={() => toggleModal(editQRCodeVerbModalVisible, setEditQRCodeVerbModalVisible)}
          isVisible={editQRCodeVerbModalVisible}
        />
      </div>
    </>
  );
}

export default QRCodePage;

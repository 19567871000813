import ProfileUpdateData from './ProfileUpdateData';

class AggregatorProfileUpdateData extends ProfileUpdateData {
    constructor(
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        linkedAccountEmailAddresses: string[]
    ) {
        super(displayName, base64ProfileImage, qrCodeVerb, linkedAccountEmailAddresses);
    }
}

export default AggregatorProfileUpdateData;

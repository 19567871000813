import {
  useEffect,
  useState,
} from 'react';

import AggregatorAccountUserProfileData
  from '../../classes/getprofilefromtoken/AggregatorAccountUserProfileData';
import PaymentAccountUserProfileData
  from '../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingIcon from '../../components/LoadingIcon';
import DefaultNavbar from '../../components/navbar/DefaultNavbar';
import PageContent from '../../components/PageContent';
import PageDefault from '../../components/PageDefault';
import HelperMethods from '../../helpers/HelperMethods';
import Logger from '../../helpers/Logger';
import {
  useAppDispatch,
  useAppSelector,
} from '../../redux/hooks';
import {
  AppDispatch,
  RootState,
} from '../../redux/store';
import { fetchUserProfileData } from '../../redux/userDataSlice';
import AggregatorSignedInPage from './AggregatorSignedInPage';
import PaymentSignedInPage from './PaymentSignedInPage';

const SignedInPage = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch<AppDispatch>();
    const userProfileData = useAppSelector((state: RootState) => state.userData.userProfileData);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await dispatch(fetchUserProfileData()).unwrap();
            } catch (err) {
                Logger.error('Failed to fetch user profile data: ' + err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    if (loading) {
        return <LoadingIcon />;
    }

    if (!userProfileData) {
        return (
            <PageDefault>
                <DefaultNavbar/>
                <PageContent>
                    <ErrorMessage errorMessage='We were unable to retrieve your profile. Please first sign-out, then reach out to our team for assistance.' />
                    <button className='error-sign-out-button' onClick={() => HelperMethods.signOut()}>Sign-out</button>
                </PageContent>
            </PageDefault>
        );
    }

    if (userProfileData instanceof PaymentAccountUserProfileData) {
        return <PaymentSignedInPage userProfileData={userProfileData} />;
    } else if (userProfileData instanceof AggregatorAccountUserProfileData) {
        return <AggregatorSignedInPage/>;
    } else {
        return <></>;
    }
}

export default SignedInPage;
import './paymentaccountdashboardpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import dayjs from 'dayjs';
import { FaCircleCheck } from 'react-icons/fa6';

import PlatformPayment
  from '../../../classes/getprofilefromtoken/PlatformPayment';
import HelperMethods from '../../../helpers/HelperMethods';

interface PaymentsPageProps {
    payments: PlatformPayment[];
}

const PaymentsPage = (props: PaymentsPageProps): ReactElement => {
    const { payments } = props;

    // const payments: PlatformPayment[] = paymentss.flatMap(item => Array(5).fill(item));

    // Pagination state
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10; // Number of payments to display per page

    // Calculate total pages
    const totalPages = Math.ceil(payments.length / itemsPerPage);

    // Get current payments to display
    const indexOfLastPayment = currentPage * itemsPerPage;
    const indexOfFirstPayment = indexOfLastPayment - itemsPerPage;
    const currentPayments = payments.slice(indexOfFirstPayment, indexOfLastPayment);

    // Handle page change
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    if (!payments || payments.length === 0) {
        return <p className='no-transactions-message'>Once you start receiving Payments they will appear here</p>
    }
    
    return (
        <div className='transaction-list'>
            { currentPage === 1 &&
                <p className='transaction-page-description'>Payments made to you via your QR code</p>
            }
            {currentPayments.map((payment, index) => (
                <div key={index} className={`transaction-item ${index === currentPayments.length - 1 ? 'last' : ''}`}>
                    <div className='transaction-status-section'>
                        <FaCircleCheck className='transaction-icon paid'/>
                    </div>
                    <div className='transaction-details-section'>
                        <p className='transaction-amount'><b>{`${HelperMethods.getCurrencySymbolFromString(payment.currency)}${payment.amount.toFixed(2)}`}</b></p>
                        <p className='transaction-date'>{`on ${dayjs(payment.created*1000).format('DD MMM, HH:mm')}`}</p>
                    </div>
                </div>
            ))}

            { totalPages > 1 &&
                <div className='transaction-pagination-controls'>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`transaction-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
            }
        </div>
    );
}

export default PaymentsPage;

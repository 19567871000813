class ProfileUpdateData {
    static type: string = 'ProfileUpdateRequest';

    displayName: string;
    base64ProfileImage: string;
    qrCodeVerb: string;
    linkedAccountEmailAddresses: string[]

    constructor(
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        linkedAccountEmailAddresses: string[]
    ) {
        this.displayName = displayName;
        this.base64ProfileImage = base64ProfileImage;
        this.qrCodeVerb = qrCodeVerb;
        this.linkedAccountEmailAddresses = linkedAccountEmailAddresses;
    }
}

export default ProfileUpdateData;

import './aboutpage.scss';

import { ReactElement } from 'react';

import PageContent from '../../../components/PageContent';
import Constants from '../../../configuration/constants';

const AboutPage = (): ReactElement => {
    return (
        <PageContent alignItems='left'>
            <h1>About Us</h1>
            <div className='about-logo-banner'>
                <img className='about-logo' src={require(`../../../assets/images/logos/${Constants.primaryLogoWhite}`)}/>
            </div>
            <p>{Constants.tradingName} was founded with the mission to improve how people receive tips, donations and financial support as part of their work, hobby or cause.</p>
            <p>As cash is becoming less and less common, especially in large towns and cities, many people have seen cash reliant earnings decline significantly. We believe people still want to show their appreciation for an excellent service or experience, they just haven't had a convenient way to do this.</p>
            <p>That's why we created {Constants.tradingName}: a clean, streamlined and transparent way for people to leave cashless payments via QR codes.</p>
            <p>We believe we have created the most user-friendly and customisable QR code payments service there is. We’d love for you to try it out and tell us what you think!</p>
        </PageContent>
    );
}

export default AboutPage;

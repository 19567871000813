class UpdatePayoutScheduleResponse {
    static type: string = 'UpdatePayoutSchedulResponse';
    
    profileUpdateSuccess: boolean;
      
    constructor(data: UpdatePayoutScheduleResponse) {
        this.profileUpdateSuccess = data.profileUpdateSuccess;
    }
}

export default UpdatePayoutScheduleResponse;

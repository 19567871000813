class SendQRCodeByEmailRequest {
    static type: string = 'SendQRCodeByEmailRequest';
    
    base64EncodedQRCode: string;
      
    constructor(base64EncodedQRCode: string) {
        this.base64EncodedQRCode = base64EncodedQRCode;
    }
}

export default SendQRCodeByEmailRequest;

import {
  AccountType,
  Address,
} from '../../pages/createaccount/types/types';
import AccountCreateData from './AccountCreateData';

export class AggregatorAccountCreateData extends AccountCreateData {
    constructor(
        username: string,
        displayName: string,
        firstName: string,
        lastName: string,
        industryType: string,
        country: string,
        address: Address,
        dob: string,
        phoneNumber: string
    ) {
        super(
            username,
            displayName,
            firstName,
            lastName,
            industryType,
            AccountType.AGGREGATOR.value,
            country,
            address,
            dob,
            phoneNumber
        );
    }
}
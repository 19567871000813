import './faqspage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import {
  FaMinus,
  FaPlus,
} from 'react-icons/fa6';

import PageContent from '../../../components/PageContent';
import TextLink from '../../../components/TextLink';
import Constants from '../../../configuration/constants';
import HelperMethods from '../../../helpers/HelperMethods';

interface FAQ {
    question: string;
    answer: ReactElement;
}

const faqs: FAQ[] = [
    {
        question: `How does ${Constants.tradingName} work?`,
        answer: (
            <>
                <p>{Constants.tradingName} provides a streamlined solution for quick and easy QR code payments:</p>
                <p><b>Step 0 - Create your {Constants.tradingName} Account</b></p>
                <p>After you have completed our streamlined sign-up process we will generate you your very own QR code! This is what you will need to display to receive payments. You can access your QR code buy downloading it or sending it to an email address of your choice.</p>
                <p>Remember, when displaying your {Constants.tradingName} QR code make sure it is visible and big enough for people to scan. Once you have it set up we recommend trying to scan your QR code yourself to make sure everything is working as you expect.</p>
                <p><b>Step 1 - Scanning your {Constants.tradingName} QR code</b></p>
                <p>A customer scans your displayed {Constants.tradingName} QR code with their phone which takes them to your personalised Payment Page.</p>
                <p><b>Step 2 - Payment</b></p>
                <p>From your Payment Page they can choose from up to 3 recommended amounts (set by you) or choose their own amount to send. Customers can make a payment to you in just 2 taps using Apple Pay, Google Pay or any other mobile payments service.</p>
                <p><b>Step 3 - Post-Payment</b></p>
                <p>Once a payment is completed successfully the customer is shown your Confirmation Page. On your Confirmation Page you can leave a personalised message to show your appreciation for their support, making for a more personal experience. You can also add links to your social media profiles to increase your online support as well!</p>
                <p>It's as simple as that.</p>
            </>
        )
    },
    {
        question: 'What about contactless card terminals?',
        answer: (
            <>
                <p>While contactless card terminals may work for some users, we believe QR codes have the following advantages:</p>
                <p><b>Fees</b></p>
                <p>As well as the transaction fees (which are always paid by the owner of the terminal), contactless card terminals almost always require monthly rental payments. This means whether you are receiving payments or not you are always paying for your terminal.</p>
                <p>Alternatively, when using your {Constants.tradingName} QR code there are only fees when transactions are processed. Also, unlike with terminals customers have the option to (and usually do) cover these fees for you, meaning for the majority of transactions you pay no fee at all!</p>
                <p><b>Simultaneous Payments</b></p>
                <p>Having a card terminal means only one customer is able to make a payment at a time, and only when you present the terminal at the end of a performance or display. These limitations greatly reduce the time window customers can make payments.</p>
                <p>With an {Constants.tradingName} QR code, if you display your QR code before your performance not only can multiple customers make payments simultaneously, but they don't have to wait until you finish to show their support!</p>
                <p><b>Custom Amounts</b></p>
                <p>Rather than having one pre-set fixed amount with contactless terminals, with {Constants.tradingName} users can choose how much they want to send to you. You are even able to set your own suggested amounts which they can choose from on your Payment Page!</p>
                <p><b>A More Personal Experience</b></p>
                <p>Contactless terminals don't let you express yourself!. With {Constants.tradingName} customers can see your photo, see a Thank You message from you on your Confirmation Page and have links to all your social media profiles.</p>
                <p>Not only does this provide a more memorable experience for those supporting you, it also means you are able to increase your online support as well!</p>
            </>
        )
    },
    {
        question: 'How can I customise my Profile?',
        answer: (
            <>
                <p>{Constants.tradingName} provides many way to customise your Profile:</p>
                <p><b>Your Payment Page</b></p>
                <p>Your Payment Page is what customers will see when they scan your QR code. On this page you can customise your profile image, display name and suggested amounts.</p>
                <p><b>Your Confirmation Page</b></p>
                <p>Your Confirmation Page is what customers see when they complete a transaction. On this page you can customise your Thank You message and add links to your social media profiles.</p>
                <p><b>Your {Constants.tradingName} QR Code</b></p>
                <p>You can choose to have an Action Verb displayed on your QR code to make it easier for customers to see how they can support you.</p>
                <p><b>Your Payout schedule</b></p>
                <p>{Constants.tradingName} lets you choose the payout schedule that works best for you.</p>
                <p>You can set your payouts to be daily, weekly, or if you prefer on a specific day of the week or month. Whatever suits you best.</p>
                <p>You can change your payout schedule at any timeon the Payouts tab within your Payments dashboard.</p>
            </>
        )
    },
    {
        question: `Is ${Constants.tradingName} secure?`,
        answer: (
            <>
                <p>Yes.</p>
                <p>Your financial security is the most important thing to us. That's why we've partnered with the industry leading payment processor used by most online retailers and finanical institutions.</p>
                <p>{Constants.tradingName} never sees or stores your card details. Instead, this information goes through our secure payment processing partner. For more information see <TextLink href='https://docs.stripe.com/security' text='here'/>.</p>
            </>
        )
    },
    {
        question: 'What does it cost?',
        answer: (
            <>
                <p>Setting up an {Constants.tradingName} account is completely free! We don't charge any sign-up or subscription fees.</p>
                <p>While there are fees associated with all online transactions, our goal is to have these fees covered by the customer scanning your QR code. That's why we have set up our platform so customers have the option to cover the fees for you to make sure you get 100% of your tip!</p>
                <p>The fees:</p>
                <p><b>Payment Processing fee</b></p>
                <p>All online payments have a processing fee. Sadly we can't avoid this. BUT, the good news is that the more we grow the more we can lower this in the future.</p>
                <p><b>Platform fee</b></p>
                <p>This is just {HelperMethods.getFeeString(Constants.platformFeeMultiplier, Constants.platformFeeConstant, 'gbp')} and is the fee we have to take to cover our costs and to try and support as many people as possible.</p>
            </>
        )
    },
    {
        question: 'What if I lose my log in details?',
        answer: (
            <>
                <p>You can't!</p>
                <p>{Constants.tradingName} uses passwordless authentication. This means rather than having a traditional password, we will send a one-time code to your email address whenever you want to log in.</p>
                <p>As well as meaning you have one less password to remember (phew!), passwordless authentication reduces the risk of password-related vulnerabilities like weak or reused passwords, phishing, and credential theft.</p>
            </>
        )
    },
    {
        question: 'What is Stripe?',
        answer: (
            <>
                <p>Stripe is the leading online payment processor in the industry. They make sure online payments are processed safely and quickly, whether using a credit card or another payment method.</p>
                <p>To maximise your security you may have to complete 2-factor authentication with Stripe when updating your bank details. This is the best way to ensure your financial information remains safe and secure!</p>
                <p>Remember, {Constants.tradingName} doesn't see or store <b>any</b> of your financial information.</p>
            </>
        )
    }
];

const FAQsPage = (): ReactElement => {
    const [isFaqVisible, setIsFaqVisible] = useState<boolean[]>(Array(faqs.length).fill(false));

    const toggleFaqVisibility = (index: number): void => {
        setIsFaqVisible((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    return (
        <PageContent alignItems='left' fullWidth>
            <h1 className='faq-title'>FAQs</h1>
            { faqs.map((faq, index) => (
                <div
                    key={index}
                    className={`faq ${index === faqs.length-1 ? 'last-item' : ''}`}>
                    <div className='faq-question-bar' onClick={() => toggleFaqVisibility(index)}>
                        <p className='faq-question-bar-text'>{faq.question}</p>
                        { isFaqVisible[index] ? <FaMinus className='faq-question-bar-icon'/> : <FaPlus className='faq-question-bar-icon'/> }
                    </div>
                    { isFaqVisible[index] &&
                        <div className='faq-answer'>{faq.answer}</div>
                    }
                </div>
            ))}
        </PageContent>    
    );
}

export default FAQsPage;

import AccountCreateData from './AccountCreateData';

class CreateAccountRequest {
  static type: string = 'CreateAccountRequest';
  
  accountCreateData: AccountCreateData;
  accountType: string;
  recaptchaToken: string|null;
    
  constructor(accountCreateData: AccountCreateData, recaptchaToken: string|null) {
    this.accountCreateData = accountCreateData;
    this.accountType = accountCreateData.accountType;
    this.recaptchaToken = recaptchaToken;
  }
}

export default CreateAccountRequest;

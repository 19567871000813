import {
  AccountType,
  Address,
} from '../../pages/createaccount/types/types';
import PaymentAccountCreateData from './PaymentAccountCreateData';

class IndividualAccountCreateData extends PaymentAccountCreateData {
    constructor(
        username: string,
        displayName: string,
        firstName: string,
        lastName: string,
        industryType: string,
        country: string,
        address: Address,
        dob: string,
        phoneNumber: string,
        currency: string,
        url: string
    ) {
        super(
            username,
            displayName,
            firstName,
            lastName,
            industryType,
            AccountType.INDIVIDUAL.value,
            country,
            address,
            dob,
            phoneNumber,
            currency,
            url
        );
    }
}

export default IndividualAccountCreateData;

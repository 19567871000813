import HelperMethods from '../../helpers/HelperMethods';
import { Address } from '../../pages/createaccount/types/types';

class AccountCreateData {
    username: string;
    displayName: string;
    firstName: string;
    lastName: string;
    industryType: string;
    accountType: string;
    country: string;
    address: Address;
    dob: DoB | null;
    phoneNumber: string;
    base64ProfileImage: string;

    constructor(
        username: string,
        displayName: string,
        firstName: string,
        lastName: string,
        industryType: string,
        accountType: string,
        country: string,
        address: Address,
        dob: string,
        phoneNumber: string
    ) {
        this.username = username.toLowerCase();
        this.displayName = displayName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.industryType = industryType;
        this.accountType = accountType;
        this.country = country;
        this.address = this.getAddress(address);
        this.dob =  dob ? this.getDoB(dob) : null;
        this.phoneNumber = HelperMethods.getValidPhoneNumber(phoneNumber);;
        this.base64ProfileImage = require('../../assets/images/default-profile-image.jpg');
    }

    private getDoB(value: string): DoB {
        const dobArray: string[] = value.split('-');

        return new DoB(parseInt(dobArray[0]), parseInt(dobArray[1]), parseInt(dobArray[2]));
    }

    private getAddress(address: Address): Address {
        const getStringOrNull = (value: string | undefined | null): string | null => value?.trim() ? value : null;

        return {
            line1: getStringOrNull(address.line1),
            line2: getStringOrNull(address.line2),
            postalCode: getStringOrNull(address.postalCode),
            city: getStringOrNull(address.city),
            state: getStringOrNull(address.state),
            country: getStringOrNull(address.country)
        };
    }
}

class DoB {
    day: number;
    month: number;
    year: number;

    constructor(year: number, month: number, day: number) {
        this.year = year;
        this.month = month;
        this.day = day;
    }
}

export default AccountCreateData;

import './vieweditprofilepage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import PaymentAccountUserProfileData
  from '../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import PaymentConfirmationPageViewEditTab
  from './PaymentConfirmationPageViewEditTab';
import PaymentPageViewEditTab from './PaymentPageViewEditTab';

interface ViewEditProfileTabItem {
    name: string;
    element: JSX.Element;
};

interface PaymentViewEditProfilePageProps { 
    userProfileData: PaymentAccountUserProfileData;
}

const PaymentViewEditProfilePage = (props: PaymentViewEditProfilePageProps): ReactElement => {
    const { userProfileData } = props;

    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

    const tabs: ViewEditProfileTabItem[] = [
        { name: 'Payment Page', element: <PaymentPageViewEditTab userProfileData={userProfileData}/> },
        { name: 'Confirmation Page', element: <PaymentConfirmationPageViewEditTab userProfileData={userProfileData}/> }
    ];

    return (
        <div className='view-edit-profile-page-wrapper'>
            <p className='view-edit-profile-page-message'>View & Edit your Profile here</p>
            <div className='view-edit-profile-navbar'>
                <div className='view-edit-profile-navbar-content'>
                    { tabs.map((item, index) => (
                        <div 
                            key={index}
                            className={`view-edit-profile-navbar-button ${selectedTabIndex === index ? 'selected' : ''}`}
                            onClick={() => setSelectedTabIndex(index)}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
            </div>
            <div className='view-edit-profile-tab-content'>
                { tabs[selectedTabIndex].element }
            </div>
        </div>
    );
}

export default PaymentViewEditProfilePage;

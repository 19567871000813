import { configureStore } from '@reduxjs/toolkit';

import userDataReducer from './userDataSlice';

export const store = configureStore({
  reducer: {
    userData: userDataReducer,  // Change to 'userData' here
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

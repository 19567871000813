class LocalStoragItem {
    static type: string = 'LocalStoragItem';
    
    data: any;
    timestamp: number;
      
    constructor(data: any) {
        this.data = data;
        this.timestamp = new Date().getTime();
    }
}

export default LocalStoragItem;

import './landingpage.scss';

import { ReactElement } from 'react';

import { FaCircleCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router';

import NotSignedInNavbar from '../../components/navbar/NotSignedInNavbar';
import PageContent from '../../components/PageContent';
import PageDefault from '../../components/PageDefault';
import PageFooter from '../../components/PageFooter';
import Constants from '../../configuration/constants';

interface LandingPageListProps {
    items: string[];
    leftAligned: boolean;
}

const LandingPageList = (props: LandingPageListProps) => {
    const { items, leftAligned } = props;

    return (
        <div className='landing-page-bullet-list'>
            { items.map((item, index) => (
                <div key={index} className={`landing-page-bullet-list-item ${leftAligned ? 'left-aligned' : ''} ${index === items.length - 1 ? 'last' : ''}`}>
                    <FaCircleCheck className='landing-page-bullet-list-check'/>
                    <p className={leftAligned ? 'left-aligned' : ''}>{item}</p>
                </div>
            ))}
        </div>
    );
}

interface LandingPageContentSectionProps {
    title: string;
    content: ReactElement;
}

const LandingPageContentSection = (props: LandingPageContentSectionProps) => {
    const { title, content } = props;

    return (
        <div className='landing-page-content-tile'>
            <div className='landing-page-content-content'>
                <h2>{title}</h2>
                { content }
            </div>
        </div>
    );
}

interface LandingPageImageSectionProps {
    title: string;
    imageSrc: NodeRequire;
    description: string;
}

const LandingPageImageSection = (props: LandingPageImageSectionProps) => {
    const { title, imageSrc, description } = props;
    return (
        <div className='landing-page-image-section'>
            <h2>{title}</h2>
            <img src={imageSrc.toString()}/>
            <p>{description}</p>
        </div>
    );
}

const LandingPage = (): ReactElement => { 
    const navigate = useNavigate();
    
    return (
        <PageDefault backgroundColor='black'>
            <NotSignedInNavbar isLandingPage={true}/>
            <PageContent fullWidth={true}>
                <h1 className='landing-page-heading-text'>The UKs most advanced <span className='orange'>cashless tipping</span> solution</h1>
                <h2 className='landing-page-header-image-text'>Sleek mobile payments, in the palm of their hand</h2>
                <img className='landing-page-header-image' src={require('../../assets/images/landing-page/mock-phone-in-hand.png')}/>
                <LandingPageContentSection
                    title='How it works'
                    content={(
                        <>
                            <p>If you receive tips or donations as part or all of your income, {Constants.tradingName} can help boost how much you earn by enabling you to receive mobile payments, including Apple Pay and Google Pay.</p>
                            <p>Our solution requires no sign-up fee, and creating an account can be completed in just a few simple steps.</p>
                            <div className='landing-page-button orange' onClick={() => navigate(Constants.authenticatePagePath)}>Sign-up for free</div>
                        </>
                    )}
                />
                <LandingPageImageSection
                    title='Insight Full'
                    imageSrc={require('../../assets/images/landing-page/phone-mockup-data.png')}
                    description='View the data you want to see most with our intuitive Payments dashboards'
                />
                <LandingPageContentSection
                    title='Who is it for?'
                    content={(
                        <>
                            <p>{Constants.tradingName} is for anyone who collects payments, including donations and tips, and wants to be able to receive mobile payments in a streamlined and cost efficient way.</p>
                            <LandingPageList
                                leftAligned={false}
                                items={['Hair Salons', 'Barber Shops', 'Tattoo Parlours', 'Cafés', 'Beauty Salons', 'Restaurants', 'Musicians', 'Street Performers', 'Artists', 'Drivers', 'Charities']
                            }/>
                            <p>...and many more</p>
                            <p>Speak to our team today to learn more about how {Constants.tradingName} can help you.</p>
                            <div className='landing-page-button' onClick={() => navigate(Constants.contactPagePath)}>Contact Us</div>
                        </>
                    )}
                />
                <LandingPageImageSection
                    title='Express Yourself'
                    imageSrc={require('../../assets/images/landing-page/customization-mockup-image.png')}
                    description='A fully customisable Profile brings you closer to those who support you'
                />
                <LandingPageContentSection
                    title='Simplify Tip Management'
                    content={(
                        <>
                            <p>With an {Constants.tradingName} Aggergator Account tips are distributed directly to the recipient. This innovative approach:</p>
                            <LandingPageList
                                leftAligned={true}
                                items={[
                                    'Saves businesses time and resources on tip allocation',
                                    'Ensures 100% of tips reach your workers without business deductions',
                                    'Eliminates the need for record-keeping and policy documentation'
                                ]}
                            />
                            <p>Focus on running your business while we handle the complexities of tip management in compliance with the Allocation of Tips Act.</p>
                            <div className='landing-page-button' onClick={() => navigate(Constants.allocationOfTipsActPagePath)}>Allocation of Tips Act</div>
                        </>
                    )}
                />
                <LandingPageContentSection
                    title='About'
                    content={(
                        <>
                            <p>With more and more transactions going digital and less people carrying cash, we thought it was about time someone made a solution to allow people accept online payments in a sleek, efficient and enjoyable way.</p>
                            <p>We have created {Constants.tradingName} with both the user and the end customer in mind. By doing this we believe we have created the best QR code payments platform available.</p>
                            <div className='landing-page-button' onClick={() => navigate(Constants.faqsPagePath)}>Learn more</div>
                        </>
                    )}
                />
            </PageContent>
            <PageFooter backgroundColour='black'/>
        </PageDefault>
    );
}

export default LandingPage;

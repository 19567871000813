import './payoutscheduleedit.scss';

import { ReactElement } from 'react';

import PaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import PayoutSchedule, {
  PayoutScheduleInterval,
  PayoutScheduleWeeklyAnchor,
} from '../../../classes/updatepayoutschedule/PayoutSchedule';
import Constants from '../../../configuration/constants';
import HelperMethods from '../../../helpers/HelperMethods';
import { EditAccountProfileModalBodyProps } from './types';

const PayoutScheduleEdit = (props: EditAccountProfileModalBodyProps<PaymentAccountUserProfileData>): ReactElement => {
    const { updatedUserProfileData, updateAccountUserProfileData, setHasMadeEdit } = props;

    const onChangePayoutScheduleInterval = (interval: PayoutScheduleInterval): void => {
        setHasMadeEdit(true);

        // Determine the new anchors based on the interval change
        const updatedPayoutSchedule = new PayoutSchedule(
            interval,
            interval === 'weekly' ? 'monday' : null, // Set weeklyAnchor to 'monday' for weekly
            interval === 'monthly' ? 1 : null // Set monthlyAnchor to 1 for monthly
        );

        updateAccountUserProfileData(updatedUserProfileData, {
            payoutSchedule: updatedPayoutSchedule
        });
    };

  const onChangePayoutScheduleAnchor = (anchor: PayoutScheduleWeeklyAnchor | number): void => {
    setHasMadeEdit(true);
    
    // Determine the new anchors based on the anchor type
    const updatedPayoutSchedule = new PayoutSchedule(
        updatedUserProfileData.payoutSchedule.interval,
        typeof anchor === 'string' ? anchor : null,
        typeof anchor === 'number' ? anchor : null
    );

    updateAccountUserProfileData(updatedUserProfileData, {
        payoutSchedule: updatedPayoutSchedule
    });
  }
  
  return (
    <>
        <div className='edit-payout-modal-section'>
            <p className='edit-payout-modal-text'>Payout</p>
            <select
                className='edit-payout-modal-select'
                onChange={(event) => onChangePayoutScheduleInterval(event.target.value as PayoutScheduleInterval)}
                value={updatedUserProfileData.payoutSchedule.interval}
            >
                { Constants.validPayoutScheduleIntervals.map((interval: PayoutScheduleInterval, index: number) => (
                    <option key={index} value={interval}>
                        {HelperMethods.capitalizeFirstLetter(interval)}
                    </option>
                ))}
            </select>
        </div>
        { updatedUserProfileData.payoutSchedule.interval !== 'daily' &&
            <div className='edit-payout-modal-section'>
                <p className='edit-payout-modal-text'>On</p>
                <select
                    className='edit-payout-modal-select'
                    onChange={(event) =>
                        updatedUserProfileData.payoutSchedule.interval === 'weekly'
                            ? onChangePayoutScheduleAnchor(event.target.value as PayoutScheduleWeeklyAnchor)
                            : onChangePayoutScheduleAnchor(parseInt(event.target.value, 10))
                    }
                    value={
                        updatedUserProfileData.payoutSchedule.interval === 'weekly'
                            ? updatedUserProfileData.payoutSchedule.weeklyAnchor || '' // Fallback to empty string
                            : updatedUserProfileData.payoutSchedule.monthlyAnchor?.toString() || '' // Convert to string and fallback
                    }
                >
                    { updatedUserProfileData.payoutSchedule.interval === 'weekly' ?
                        ( Constants.validPayoutScheduleWeeklyAnchors.map((weeklyAnchor: PayoutScheduleWeeklyAnchor, index: number) => (
                            <option key={index} value={weeklyAnchor as string}>
                                {HelperMethods.capitalizeFirstLetter(weeklyAnchor as string)}
                            </option>
                        )))
                        :
                        ( Array.from({ length: 31 }, (_, index) => (
                            <option key={index + 1} value={(index + 1).toString()}>
                                {HelperMethods.getOrdinalSuffix(index + 1)}
                            </option>
                        )))
                    }
                </select>
            </div>
        }
    </>
  );
};

export default PayoutScheduleEdit;

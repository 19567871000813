import { useState } from 'react';

import SignedInAggregatorNavbar
  from '../../components/navbar/SignedInAggregatorNavbar';
import PageContent from '../../components/PageContent';
import PageDefault from '../../components/PageDefault';
import QRCodePage from '../qrcode/QRCodePage';
import SignedInProfilePage from '../signedinprofilepage/SignedInProfilePage';

interface SignedInPageInterface {
  element: JSX.Element;
}

const AggregatorSignedInPage = () => {
  const [pageIndex, setPageIndex] = useState<number>(0);

  const signedInPages: SignedInPageInterface[] = [
    { element: <SignedInProfilePage/> },
    { element: <QRCodePage/> }
  ];

  return (
    <PageDefault>
      <SignedInAggregatorNavbar pageIndex={pageIndex} setPageIndex={setPageIndex}/>
      <PageContent>
        {signedInPages[pageIndex].element}
      </PageContent>
    </PageDefault>
  );
}

export default AggregatorSignedInPage;

import { AccountType } from '../../pages/createaccount/types/types';
import LinkedAccountData from '../getprofilefromtoken/LinkedAccountData';
import ProfileDisplayData from './ProfileDisplayData';

class AggregatorProfileDisplayData extends ProfileDisplayData {
    linkedAccountsData: LinkedAccountData[];

    constructor(
        username: string,
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        linkedAccountsData: LinkedAccountData[]
    ) {
        super(
            username,
            displayName,
            base64ProfileImage,
            qrCodeVerb,
            AccountType.AGGREGATOR.value
        );

        this.linkedAccountsData = linkedAccountsData;
    }
}

export default AggregatorProfileDisplayData;

import './paymentnavbar.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { CiCircleQuestion } from 'react-icons/ci';

import Constants from '../../configuration/constants';
import PlatformModal from '../PlatformModal';
import TextLink from '../TextLink';

const PaymentNavbar = (): ReactElement => {
    const [whoAreWeModalVisible, setWhoAreWeModalVisible] = useState<boolean>(false);

    const toggleModal = () => {
        setWhoAreWeModalVisible(!whoAreWeModalVisible);
    }

    return (
        <div className='payment-navbar'>
            <div className='payment-central-navbar'>
                <div className='who-are-we-icon-wrapper' onClick={toggleModal}>
                    <CiCircleQuestion className='who-are-we-icon'/>
                </div>
                { whoAreWeModalVisible &&
                    <PlatformModal
                        modalTitle={`What is ${Constants.tradingName}?`}
                        modalBody={
                            <div>
                                <p className='who-are-we-modal-text'>
                                {Constants.tradingName} is a London based start-up with the goal of revolutionising how poeple receive mobile payments.
                                </p>
                                <p className='who-are-we-modal-text'>
                                    We have partnered with the industry-leading payment processor to ensure your transactions are reliable and always secure. {Constants.tradingName} does not see or store <b>any</b> of your financial data.
                                </p>
                                <p className='who-are-we-modal-text'>
                                    Want to know more? <TextLink text='Visit us' href={Constants.frontendURL}/>.
                                </p>
                                <p className='who-are-we-modal-text'>
                                    <i>{Constants.registeredCompanyName} is a UK registered business</i>
                                </p>
                            </div>
                          }
                          onClose={toggleModal}
                          isVisible={whoAreWeModalVisible}
                    />
                }
                <div className='payment-navbar-logo-wrapper'>
                    <img className='navbar-logo' src={require(`../../assets/images/logos/${Constants.secondaryLogoWhite}`)} alt='logo'/>
                </div>
            </div>
        </div>
    );
};

export default PaymentNavbar;

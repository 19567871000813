import HelperMethods from '../../helpers/HelperMethods';
import ProfileDisplayData from './ProfileDisplayData';

class GetProfileDisplayDataResponse {
  static type: string = 'GetProfileDisplayDataResponse';
  
  profileDisplayData: ProfileDisplayData | null;

  constructor(data: GetProfileDisplayDataResponse) {
    this.profileDisplayData = HelperMethods.getProfileDisplayDataInstanceFromData(data.profileDisplayData);
  }
}

export default GetProfileDisplayDataResponse;

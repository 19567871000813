import './suggestedpayvaluesedit.scss';

import React, {
  ReactElement,
  useState,
} from 'react';

import CurrencyInput from 'react-currency-input-field';
import {
  IoAddCircle,
  IoCloseCircle,
} from 'react-icons/io5';

import PaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import Constants from '../../../configuration/constants';
import HelperMethods from '../../../helpers/HelperMethods';
import { EditAccountProfileModalBodyProps } from './types';

const SuggestedPayValuesEdit = (props: EditAccountProfileModalBodyProps<PaymentAccountUserProfileData>): ReactElement => {
    const { updatedUserProfileData, updateAccountUserProfileData, setHasMadeEdit } = props;

    const [currentSuggestedValue, setCurrentSuggestedValue] = useState<string | undefined>(undefined);
    const [currentSuggestedPayValues, setCurrentSuggestedPayValues] = useState<number[]>(updatedUserProfileData.suggestedPayValues);
  
    const handleCurrentSuggestedValueChange = (value: string | undefined): void => {
        if (value !== undefined && (parseFloat(value) > Constants.maximumTransactionAmount || parseFloat(value) < Constants.minimumTransactionAmount)) {
            return;
        }
        setCurrentSuggestedValue(value);
    };
  
    const handleSuggestedValueInputKeyDown = (key: string): void => {
        if (key === 'Enter') {
            handleAddSuggestedValue();
        }
    };
  
    const handleAddSuggestedValue = (): void => {
        if (!currentSuggestedValue || !isValidSuggestedValue()) {
            return;
        }

        setHasMadeEdit(true);
        const newSuggestedValue = parseFloat(currentSuggestedValue);
        const newCurrentSuggestedPayValues = [...currentSuggestedPayValues, newSuggestedValue];
        newCurrentSuggestedPayValues.sort((a, b) => a - b);
        setCurrentSuggestedPayValues(newCurrentSuggestedPayValues);
        setCurrentSuggestedValue(undefined);
        
        updateAccountUserProfileData(updatedUserProfileData, {suggestedPayValues: newCurrentSuggestedPayValues });
    };
  
    const isValidSuggestedValue = (): boolean => {
        if (!currentSuggestedValue) {
            return false;
        }
  
        const newSuggestedValue = parseFloat(currentSuggestedValue);
        if (currentSuggestedPayValues.length >= Constants.maxPaySelectionButtons
            || currentSuggestedPayValues.includes(newSuggestedValue)
            || newSuggestedValue < Constants.minimumTransactionAmount
            || newSuggestedValue > Constants.maximumTransactionAmount) {
            return false;
        }
  
        return true;
    };
  
    const handleRemoveSuggestedValue = (value: number): void => {
        setHasMadeEdit(true);
        const newCurrentSuggestedPayValues = currentSuggestedPayValues.filter(item => item !== value);
        setCurrentSuggestedPayValues(newCurrentSuggestedPayValues);
        // setUpdatedUserProfileData(prevState => {
        //     return HelperMethods.cloneWithUpdates(prevState, {suggestedPayValues: newCurrentSuggestedPayValues });
        // });
        updateAccountUserProfileData(updatedUserProfileData, {suggestedPayValues: newCurrentSuggestedPayValues });

    };
    
    return (
        <>
            <div className='suggested-values-selection-section'>
                {HelperMethods.getCurrencySymbolFromString(updatedUserProfileData.currency)} <CurrencyInput
                    className='suggested-value-input'
                    value={currentSuggestedValue}
                    decimalsLimit={2}
                    decimalScale={2}
                    onValueChange={(value) => handleCurrentSuggestedValueChange(value)}
                    onKeyDown={(event) => handleSuggestedValueInputKeyDown(event.key)}
                />
                <IoAddCircle className={`add-suggested-value-button ${!isValidSuggestedValue() ? 'disabled' : ''}`} onClick={handleAddSuggestedValue}/>
            </div>
            <div className='suggested-values-display-section'>
                { currentSuggestedPayValues.map((suggestedValue, index) => (
                    <div className='suggested-value-item' key={index}>{HelperMethods.getCurrencySymbolFromString(updatedUserProfileData.currency)}{suggestedValue.toFixed(2)}
                        <IoCloseCircle className='remove-suggested-value-icon' onClick={() => handleRemoveSuggestedValue(suggestedValue)}/>
                        <div className='suggested-value-tap-to-remove-text'></div>
                    </div>
                ))}
            </div>
        </>
    );
};
  
export default SuggestedPayValuesEdit;

import './paymentaccountdashboardpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { ConnectAccountManagement } from '@stripe/react-connect-js';

import PaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import PaymentsPage from './PaymentsPage';
import PayoutsPage from './PayoutsPage';

interface PaymentAccountDashboardTabItem {
    name: string;
    element: JSX.Element;
}

interface PaymentAccountDashboardPageProps {
    userProfileData: PaymentAccountUserProfileData;
}

const PaymentAccountDashboardPage = (props: PaymentAccountDashboardPageProps): ReactElement => {
    const { userProfileData } = props;

    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

    const tabs: PaymentAccountDashboardTabItem[] = [
        {name: 'Payments', element: <PaymentsPage payments={userProfileData.payments}/>},
        {name: 'Payouts', element: <PayoutsPage userProfileData={userProfileData}/>},
        {name: 'Details', element:  <ConnectAccountManagement/>}
    ]
    
    return (
        <>
            <div className='dashboard-navbar'>
                <div className='dashboard-navbar-content'>
                    {tabs.map((item, index) => (
                        <div key={index} className={`dashboard-navbar-button ${selectedTabIndex === index ? 'selected' : ''}`} onClick={() => setSelectedTabIndex(index)}>{item.name}</div>
                    ))}
                </div>
            </div>
            <div className='dashboard-tab-content'>
                {tabs[selectedTabIndex].element}
            </div>
        </>
    );
}

export default PaymentAccountDashboardPage;

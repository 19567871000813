import './contactpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { FaCircleCheck } from 'react-icons/fa6';
import { Puff } from 'react-loading-icons';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import { load } from 'recaptcha-v3';

import ApiResponse from '../../../classes/ApiResponse';
import SendCustomerContactMessageRequest
  from '../../../classes/sendcustomercontactmessage/SendCustomerContactMessageRequest';
import SendCustomerContactMessageResponse
  from '../../../classes/sendcustomercontactmessage/SendCustomerContactMessageResponse';
import ErrorMessage from '../../../components/ErrorMessage';
import {
  FormInputText,
  FormInputTextArea,
} from '../../../components/FormInputs';
import PageContent from '../../../components/PageContent';
import Constants from '../../../configuration/constants';
import StyleVariables from '../../../styles/base/variables';

export interface ContactPageFormData {
    emailAddress: string;
    message: string;
}

const ContactPage = (): ReactElement => {
    const [emailSentSuccessfully, setEmailSentSuccessfully] = useState<boolean>(false);
    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');

    const navigate = useNavigate();
    const location = useLocation();

    const isFromOverlay = !location.pathname.includes(Constants.contactPagePath);

    const form = useForm<ContactPageFormData>({
        defaultValues: {
            emailAddress: '',
            message: ''
        },
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });
    const { control, handleSubmit, formState } = form;
    const { errors, isSubmitting } = formState;

    const onSubmit = async (formData: ContactPageFormData): Promise<void> => {
        setCustomerErrorMessage('');

        const recaptcha = await load(Constants.googleReCaptchaSiteKey); // Load the reCAPTCHA
        const recaptchaToken = await recaptcha.execute('create_account'); // Execute with an action name

        if (!recaptchaToken) {
            setCustomerErrorMessage('Unable to verify reCAPTCHA. Please refresh the page and try again.');
            return;
        }

        const request = new SendCustomerContactMessageRequest(formData, recaptchaToken);
        const response: SendCustomerContactMessageResponse|null = await ApiResponse.getApiResponse(
            Constants.sendCustomerContactMessageEndpoint,
            request,
            SendCustomerContactMessageResponse,
            null,
            setCustomerErrorMessage,
            false
        );

        if (!response || !response.messageSentSuccess) {
            setCustomerErrorMessage('We weren\'t able to send your message at this time. Please check the information you provided and try again.')
        } else {
           setEmailSentSuccessfully(true);
        }
    }
    
    return ( emailSentSuccessfully ?
        <PageContent> 
            <FaCircleCheck className='message-sent-successfully-circle-check'/>
            <h2 className='message-sent-successfully-title'>Message Sent</h2>
            <p className='message-sent-successfully-text'>We will respond to your message as soon as possible</p>
            { !isFromOverlay &&
                <div className='return-to-homepage-button' onClick={() => navigate(Constants.homePagePath)}>Return Home</div>
            }
        </PageContent>
        :
        <PageContent alignItems='left'>
            <h1>Contact Us</h1>
            <p>If you have any questions or recommendations at all about our QR code solution please don't hesitate to reach out.</p>
            <p>Due to {Constants.tradingName} being a small (but growing!) start-up we are able to review every customer contact message.</p>
            <p>Once we have received and reviewed your message we will contact you with our response as soon as we can.</p>
            <p>Please complete the form below:</p>
            <form className='contact-page-form-section' onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormInputText
                    name='emailAddress'
                    label='Your Email Address'
                    placeholder='example@email.com'
                    type='email'
                    control={control}
                    helperText='The email address we will respond to'
                    errorText={errors.emailAddress?.message}
                    required={true}
                    rules={{
                        required: 'Email address is a required field',
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Invalid email address',
                        },
                        validate: {
                            leadingOrTrailingSpace: (fieldValue: string) => {
                                if (fieldValue) {
                                    return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
                                } else {
                                    return true;
                                }
                            }
                        }
                    }}
                />
                <FormInputTextArea
                    name='message'
                    label='Message'
                    placeholder='Your question or query'
                    control={control}
                    helperText='Your questions or query'
                    errorText={errors.message?.message}
                    required={true}
                    rules={{
                        required: 'Message is a required field',
                        minLength: {
                            value: 15,
                            message: 'Your message must be at least 15 characters long'
                        },
                        validate: {
                            leadingOrTrailingSpace: (fieldValue: string) => {
                                if (fieldValue) {
                                    return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
                                } else {
                                    return true;
                                }
                            }
                        }
                    }}
                />
                { customerErrorMessage !== '' &&
                    <ErrorMessage errorMessage={customerErrorMessage}/>
                }
                <div className='contact-page-form-submit-section'>
                    { !isSubmitting ?
                        <button className='contact-page-submit-form-button' disabled={isSubmitting}>Send</button>
                        :
                        <div><Puff stroke={StyleVariables.orangePrimary}/></div>
                    }
                </div>
            </form>
        </PageContent>
    );
}

export default ContactPage;

import HelperMethods from '../../helpers/HelperMethods';
import { SocialMediaLinks } from '../../types/types';
import PayoutSchedule from '../updatepayoutschedule/PayoutSchedule';
import AccountBalance from './AccountBalance';
import AccountUserProfileData from './AccountUserProfileData';
import DailyRevenueForCurrency from './DailyRevenueForCurrency';
import DailyRevenuesForCurrency from './DailyRevenueForCurrency';
import LinkedAccountData from './LinkedAccountData';
import PlatformPayment from './PlatformPayment';
import PlatformPayout from './PlatformPayout';
import TransactionTotal from './TransactionTotal';

class PaymentAccountUserProfileData extends AccountUserProfileData {
    stripeConnectedAccountId: string;
    suggestedPayValues: number[];
    thankyouMessage: string;
    socialMediaLinks: SocialMediaLinks;
    currency: string;
    receivedAmountPerCurrency: TransactionTotal[];
    accountBalancePerCurrency: AccountBalance[];
    dailyRevenuesPerCurrencyData: DailyRevenueForCurrency[];
    payments: PlatformPayment[];
    payouts: PlatformPayout[];
    payoutSchedule: PayoutSchedule;

    constructor(
        username: string,
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        accountType: string,
        stripeConnectedAccountId: string,
        suggestedPayValues: number[],
        thankyouMessage: string,
        socialMediaLinks: SocialMediaLinks,
        currency: string,
        receivedAmountPerCurrency: TransactionTotal[],
        accountBalancePerCurrency: AccountBalance[],
        dailyRevenuesPerCurrencyData: DailyRevenuesForCurrency[],
        payments: PlatformPayment[],
        payouts: PlatformPayout[],
        payoutSchedule: PayoutSchedule,
        linkedAccountsData: LinkedAccountData[]
    ) {
        super(username, displayName, base64ProfileImage, qrCodeVerb, accountType, linkedAccountsData);

        this.stripeConnectedAccountId = stripeConnectedAccountId;
        this.suggestedPayValues = HelperMethods.getValidButtonValues(suggestedPayValues);
        this.thankyouMessage = thankyouMessage;
        this.socialMediaLinks = HelperMethods.getValidSocialMediaLinks(socialMediaLinks);
        this.currency = currency;
        this.receivedAmountPerCurrency = receivedAmountPerCurrency;
        this.accountBalancePerCurrency = accountBalancePerCurrency;
        this.dailyRevenuesPerCurrencyData = dailyRevenuesPerCurrencyData;
        this.payments = payments;
        this.payouts = payouts;
        this.payoutSchedule = payoutSchedule;
    }
}

export default PaymentAccountUserProfileData;

class ProfileDisplayData {
    static type: string = 'ProfileDisplayData';

    username: string;
    displayName: string;
    base64ProfileImage: string;
    qrCodeVerb: string;
    accountType: string;

    constructor(username: string, displayName: string, base64ProfileImage: string, qrCodeVerb: string, accountType: string) {
        this.username = username;
        this.displayName = displayName;
        this.base64ProfileImage = base64ProfileImage;
        this.qrCodeVerb = qrCodeVerb;
        this.accountType = accountType;
    }
}

export default ProfileDisplayData;

import PaymentProfileUpdateData from './PaymentProfileUpdateData';

class IndividualPaymentProfileUpdateData extends PaymentProfileUpdateData {
    constructor(
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        suggestedPayValues: number[],
        thankyouMessage: string,
        socialMediaLinks: any,
        linkedAccountEmailAddresses: string[]
    ) {
        super(
            displayName,
            base64ProfileImage,
            qrCodeVerb,
            suggestedPayValues,
            thankyouMessage,
            socialMediaLinks,
            linkedAccountEmailAddresses
        );
    }
}

export default IndividualPaymentProfileUpdateData;

import { AccountType } from '../../pages/createaccount/types/types';
import AccountUserProfileData from './AccountUserProfileData';
import AggregatorAccountUserProfileData
  from './AggregatorAccountUserProfileData';
import CompanyPaymentAccountUserProfileData
  from './CompanyPaymentAccountUserProfileData';
import IndividualPaymentAccountUserProfileData
  from './IndividualPaymentAccountUserProfileData';

class GetProfileFromTokenResponse {
    static type: string = 'GetProfileFromTokenResponse';

    profileFromToken: AccountUserProfileData | null;
      
    constructor(data: GetProfileFromTokenResponse) {
        const profileFromToken = data.profileFromToken;
        
        if (!profileFromToken) {
            this.profileFromToken = null;
            return;
        }

        if (profileFromToken.accountType === AccountType.INDIVIDUAL.value) {
            const userProfileData = profileFromToken as IndividualPaymentAccountUserProfileData;
            this.profileFromToken = new IndividualPaymentAccountUserProfileData(
                userProfileData.username,
                userProfileData.displayName,
                userProfileData.base64ProfileImage,
                userProfileData.qrCodeVerb,
                userProfileData.stripeConnectedAccountId,
                userProfileData.suggestedPayValues,
                userProfileData.thankyouMessage,
                userProfileData.socialMediaLinks,
                userProfileData.currency,
                userProfileData.receivedAmountPerCurrency,
                userProfileData.accountBalancePerCurrency,
                userProfileData.dailyRevenuesPerCurrencyData,
                userProfileData.payments,
                userProfileData.payouts,
                userProfileData.payoutSchedule,
                userProfileData.linkedAccountsData
            );
        } else if (profileFromToken.accountType === AccountType.COMPANY.value) {
            const userProfileData = profileFromToken as CompanyPaymentAccountUserProfileData;
            this.profileFromToken = new CompanyPaymentAccountUserProfileData(
                userProfileData.username,
                userProfileData.displayName,
                userProfileData.base64ProfileImage,
                userProfileData.qrCodeVerb,
                userProfileData.stripeConnectedAccountId,
                userProfileData.suggestedPayValues,
                userProfileData.thankyouMessage,
                userProfileData.socialMediaLinks,
                userProfileData.currency,
                userProfileData.receivedAmountPerCurrency,
                userProfileData.accountBalancePerCurrency,
                userProfileData.dailyRevenuesPerCurrencyData,
                userProfileData.payments,
                userProfileData.payouts,
                userProfileData.payoutSchedule,
                userProfileData.linkedAccountsData
            );
        } else if (profileFromToken.accountType === AccountType.AGGREGATOR.value) {
            const userProfileData = profileFromToken as AggregatorAccountUserProfileData;
            this.profileFromToken = new AggregatorAccountUserProfileData(
                userProfileData.username,
                userProfileData.displayName,
                userProfileData.base64ProfileImage,
                userProfileData.qrCodeVerb,
                userProfileData.linkedAccountsData
            );
        } else {
            this.profileFromToken = null;
        }
    }
}

export default GetProfileFromTokenResponse;

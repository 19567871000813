import './socialmedialinksedit.scss';

import React, {
  ChangeEvent,
  ReactElement,
} from 'react';

import { AiFillTikTok } from 'react-icons/ai';
import {
  FaFacebook,
  FaInstagram,
  FaSpotify,
  FaYoutube,
} from 'react-icons/fa6';
import { TbWorldWww } from 'react-icons/tb';

import PaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import { SocialMediaPlatform } from '../../../types/types';
import { EditAccountProfileModalBodyProps } from './types';

const SocialMediaLinksEdit = (props: EditAccountProfileModalBodyProps<PaymentAccountUserProfileData>): ReactElement => {
    const { updatedUserProfileData, updateAccountUserProfileData, setHasMadeEdit } = props;

    const onChange = (event: ChangeEvent<HTMLInputElement>, socialMediaPlatform: string) => {
        setHasMadeEdit(true);
        const newSocialMediaLink = event.target.value;
        
        updateAccountUserProfileData(updatedUserProfileData, {
            socialMediaLinks: {
                ...updatedUserProfileData.socialMediaLinks,
                [socialMediaPlatform]: newSocialMediaLink
            }
        });
    };
    
    return (
        <>
            <div className='social-media-input-item' key={SocialMediaPlatform.WEBSITE.value}>
                <div className='social-media-input-field'>
                    <TbWorldWww className='social-media-icon'/>
                    <input
                        className={`social-media-input ${SocialMediaPlatform.WEBSITE.value}`}
                        type='url'
                        placeholder={SocialMediaPlatform.WEBSITE.displayName}
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.website || ''}
                        onChange={event => onChange(event, SocialMediaPlatform.WEBSITE.value)}
                    />
                </div>
            </div>
            <div className='social-media-input-item' key={SocialMediaPlatform.INSTAGRAM.value}>
                <div className='social-media-input-field'>
                    <FaInstagram className='social-media-icon'/>
                    <input
                        className={`social-media-input ${SocialMediaPlatform.INSTAGRAM.value}`}
                        type='url'
                        placeholder={SocialMediaPlatform.INSTAGRAM.displayName}
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.instagram || ''}
                        onChange={event => onChange(event, SocialMediaPlatform.INSTAGRAM.value)}
                    />
                </div>
            </div>
            <div className='social-media-input-item' key={SocialMediaPlatform.TIKTOK.value}>
                <div className='social-media-input-field'>
                    <AiFillTikTok className='social-media-icon'/>
                    <input
                        className={`social-media-input ${SocialMediaPlatform.TIKTOK.value}`}
                        type='url'
                        placeholder={SocialMediaPlatform.TIKTOK.displayName}
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.tiktok || ''}
                        onChange={event => onChange(event, SocialMediaPlatform.TIKTOK.value)}
                    />
                </div>
            </div>
            <div className='social-media-input-item' key={SocialMediaPlatform.FACEBOOK.value}>
                <div className='social-media-input-field'>
                    <FaFacebook className='social-media-icon'/>
                    <input
                        className={`social-media-input ${SocialMediaPlatform.FACEBOOK.value}`}
                        type='url'
                        placeholder={SocialMediaPlatform.FACEBOOK.displayName}
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.facebook || ''}
                        onChange={event => onChange(event, SocialMediaPlatform.FACEBOOK.value)}
                    />
                </div>
            </div>
            <div className='social-media-input-item' key={SocialMediaPlatform.YOUTUBE.value}>
                <div className='social-media-input-field'>
                    <FaYoutube className='social-media-icon'/>
                    <input
                        className={`social-media-input ${SocialMediaPlatform.YOUTUBE.value}`}
                        type='url'
                        placeholder={SocialMediaPlatform.YOUTUBE.displayName}
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.youtube || ''}
                        onChange={event => onChange(event, SocialMediaPlatform.YOUTUBE.value)}
                    />
                </div>
            </div>
            <div className='social-media-input-item' key={SocialMediaPlatform.SPOTIFY.value}>
                <div className='social-media-input-field'>
                    <FaSpotify className='social-media-icon'/>
                    <input
                        className={`social-media-input ${SocialMediaPlatform.SPOTIFY.value}`}
                        type='url'
                        placeholder={SocialMediaPlatform.SPOTIFY.displayName}
                        spellCheck={false}
                        value={updatedUserProfileData.socialMediaLinks.spotify || ''}
                        onChange={event => onChange(event, SocialMediaPlatform.SPOTIFY.value)}
                    />
                </div>
            </div>
        </>
    );
};
  
export default SocialMediaLinksEdit;

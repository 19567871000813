class InitiateAuthResponse {
    static type: string = 'InitiateAuthResponse';

    authSessionId: string;
      
    constructor(data: InitiateAuthResponse) {
        this.authSessionId = data.authSessionId;
    }
}

export default InitiateAuthResponse;

import './thankyoumessageedit.scss';

import React, {
  ChangeEvent,
  ReactElement,
} from 'react';

import PaymentAccountUserProfileData
  from '../../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import { EditAccountProfileModalBodyProps } from './types';

const ThankyouMessageEdit = (props: EditAccountProfileModalBodyProps<PaymentAccountUserProfileData>): ReactElement => {
  const { updatedUserProfileData, updateAccountUserProfileData, setHasMadeEdit } = props;

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setHasMadeEdit(true);
    const newThankyouMessage = event.target.value;
    
    updateAccountUserProfileData(updatedUserProfileData, { thankyouMessage: newThankyouMessage });
  }
  
  return (
    <>
      <textarea
        className='thankyou-message-input'
        placeholder='Thank you for the support!'
        spellCheck={true}
        value={updatedUserProfileData.thankyouMessage}
        onChange={onChange}
      />
    </>
  );
};
  
export default ThankyouMessageEdit;
  
import './notsignedinnavbar.scss';

import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  IoMenu,
  IoMenuOutline,
} from 'react-icons/io5';
import { useNavigate } from 'react-router';

import Constants from '../../configuration/constants';

interface NavbarDropdownItem {
    name: string;
    link: string;
}

const dropdownItems: NavbarDropdownItem[] = [
    {name: 'Home', link: Constants.homePagePath},
    {name: 'About', link: Constants.aboutPagePath},
    {name: 'Contact', link: Constants.contactPagePath},
    {name: 'FAQs', link: Constants.faqsPagePath},
    // {name: 'Tax on Tips', link: Constants.taxOnTipsPagePath},
    {name: 'Allocation of Tips Act', link: Constants.allocationOfTipsActPagePath},
    {name: 'Tipping Tips', link: Constants.tippingTipsPagePath}
];

interface NotSignedInNavbarProps {
    isLandingPage?: boolean;
}

const NotSignedInNavbar = (props: NotSignedInNavbarProps): ReactElement => {
    const { isLandingPage } = props;

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = (event: React.MouseEvent) => {
        event.stopPropagation(); // Stops the closeDropdown() method from executing
        setIsDropdownOpen(prev => !prev);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                closeDropdown();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={`not-signed-in-navbar ${isLandingPage ? 'landing-page' : ''}`}>
            <div className={`not-signed-in-central-navbar ${isLandingPage ? 'landing-page' : ''}`}>
                <div className='not-signed-in-navbar-menu-logo'>
                    <div
                        className='not-signed-in-navbar-menu-icon-wrapper'
                        onClick={toggleDropdown}
                    >
                        { isDropdownOpen ?
                            <IoMenu className='not-signed-in-navbar-menu-icon' />
                            :
                            <IoMenuOutline className='not-signed-in-navbar-menu-icon' />
                        }
                        { isDropdownOpen && (
                            <div ref={dropdownRef} className='navbar-dropdown'>
                                { dropdownItems.map((item, index) => (
                                    <p key={index} className='navbar-dropdown-item' onClick={() => navigate(item.link)}>{item.name}</p>
                                ))}
                            </div>
                        )}
                    </div>
                    <img
                        className='not-signed-in-navbar-logo'
                        onClick={() => navigate(Constants.homePagePath)}
                        src={require(`../../assets/images/logos/${Constants.primaryLogoWhite}`)}
                        alt='logo'
                    />
                </div>
                <button
                    className='not-signed-in-navbar-sign-in'
                    onClick={() => navigate('/authenticate')}
                >
                    Sign-in | Sign-up
                </button>
            </div>
        </div>
    );
};

export default NotSignedInNavbar;

import './paymentconfirmationpageprofile.scss';

import {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import { AiFillTikTok } from 'react-icons/ai';
import {
  FaCircleCheck,
  FaFacebook,
  FaInstagram,
  FaSpotify,
  FaYoutube,
} from 'react-icons/fa6';
import { TbWorldWww } from 'react-icons/tb';
import { TfiPencil } from 'react-icons/tfi';

import PaymentProfileDisplayData
  from '../../classes/getprofiledisplaydata/PaymentProfileDisplayData';
import PaymentAccountUserProfileData
  from '../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import { SocialMediaPlatform } from '../../types/types';
import EditProfileModal, {
  ProfileAttribute,
} from '../vieweditprofile/editprofilemodal/EditProfileModal';

interface PaymentConfirmationPageProfileProps {
  paymentProfileDisplayData: PaymentProfileDisplayData;
  userProfileData?: PaymentAccountUserProfileData;
  isPreview: boolean;
}

const PaymentConfirmationPageProfile = (props: PaymentConfirmationPageProfileProps) => {
  const { paymentProfileDisplayData, userProfileData, isPreview } = props;

  const [profileAttribute, setProfileAttribute] = useState<ProfileAttribute>('thankyouMessage');
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const toggleEditModal = (isModalVisible: boolean, setIsModalVisibleState: Dispatch<SetStateAction<boolean>>, profileAttribute: ProfileAttribute): void => {
    setProfileAttribute(profileAttribute);
    setIsModalVisibleState(!isModalVisible);
  }

  return (
    <div className='thankyou-page-centre-content'>
      <FaCircleCheck className='thankyou-page-circle-check'/>
      <h2 className='payment-successful-text'>Payment Successful</h2>
      { paymentProfileDisplayData?.thankyouMessage &&
        <>
          <div className='a-message-from'>A message{paymentProfileDisplayData.displayName ? ` from ${paymentProfileDisplayData.displayName}:` : ':'}</div>
          <div className='message-section'>
            {paymentProfileDisplayData.base64ProfileImage && 
              <img className='thankyou-page-profile-image' src={paymentProfileDisplayData.base64ProfileImage} alt='logo'/>
            }
            <div className='message-box'>
              { isPreview &&
                <div className='edit-thankyou-message-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'thankyouMessage')}>
                  <TfiPencil className='edit-profile-pencil-icon'/> 
                </div>
              }
              <div className='message-text'>{paymentProfileDisplayData.thankyouMessage}</div>
            </div>
          </div>
        </>
      }
      { isPreview && (!paymentProfileDisplayData?.thankyouMessage || paymentProfileDisplayData.thankyouMessage === '') &&
        <div className='add-profile-content-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'thankyouMessage')}>
          Add Thank you message
        </div>
      }
      { paymentProfileDisplayData?.socialMediaLinks && Object.keys(paymentProfileDisplayData.socialMediaLinks).length > 0 &&
        <div className='social-media-section'>
          <div className='find-on-text'>Find {paymentProfileDisplayData.displayName ? `${paymentProfileDisplayData.displayName}` : ''} on:</div>
          <div className='social-media-icon-section'>
            { isPreview &&
              <div className='edit-social-media-links-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'socialMediaLinks')}>
                <TfiPencil className='edit-profile-pencil-icon'/> 
              </div>
            }
            { Object.entries(paymentProfileDisplayData.socialMediaLinks)
              .filter(([_, link]) => link && link.trim() !== '')
              .map(([socialMedia, link], index) => (
                <div key={index}>
                  { link && (
                    <a href={link} className={`thankyou-page-social-media-icon ${isPreview ? 'disabled' : ''}`} target='_blank' rel='noopener noreferrer'>
                      {socialMedia === SocialMediaPlatform.WEBSITE.value && <TbWorldWww/>}
                      {socialMedia === SocialMediaPlatform.INSTAGRAM.value && <FaInstagram/>}
                      {socialMedia === SocialMediaPlatform.TIKTOK.value && <AiFillTikTok/>}
                      {socialMedia === SocialMediaPlatform.FACEBOOK.value && <FaFacebook/>}
                      {socialMedia === SocialMediaPlatform.YOUTUBE.value && <FaYoutube/>}
                      {socialMedia === SocialMediaPlatform.SPOTIFY.value && <FaSpotify/>}
                    </a>
                  )}
                </div>
              ))
            }
          </div>
        </div>
      }
      { isPreview && (!paymentProfileDisplayData?.socialMediaLinks || Object.keys(paymentProfileDisplayData.socialMediaLinks).length === 0) &&
        <div className='add-profile-content-button' onClick={() => toggleEditModal(editModalVisible, setEditModalVisible, 'socialMediaLinks')}>
          Add Social Media Links
        </div>
      }
      { isPreview && userProfileData &&
        <EditProfileModal
          userProfileData={userProfileData}
          profileAttribute={profileAttribute}
          onClose={() => toggleEditModal(editModalVisible, setEditModalVisible, profileAttribute)}
          isVisible={editModalVisible}
        />
      }
    </div>
  );
}

export default PaymentConfirmationPageProfile;

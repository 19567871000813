import ProfileUpdateData from './ProfileUpdateData';

class ProfileUpdateRequest {
    static type: string = 'ProfileUpdateRequest';
    
    profileUpdateData: ProfileUpdateData|null;
    accountType: string;
      
    constructor(data: ProfileUpdateData, accountType: string) {
        this.profileUpdateData = data;
        this.accountType = accountType;
    }
}

export default ProfileUpdateRequest;

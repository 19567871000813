import './qrcodeverbedit.scss';

import {
  ChangeEvent,
  ReactElement,
} from 'react';

import AccountUserProfileData
  from '../../../classes/getprofilefromtoken/AccountUserProfileData';
import Constants from '../../../configuration/constants';
import { EditAccountProfileModalBodyProps } from './types';

const QRCodeVerbEdit = (props: EditAccountProfileModalBodyProps<AccountUserProfileData>): ReactElement => {
    const { updatedUserProfileData,
        updateAccountUserProfileData,
        setHasMadeEdit } = props;

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setHasMadeEdit(true);
        const newQRCodeVerb = event.target.value;
        
        updateAccountUserProfileData(updatedUserProfileData, { qrCodeVerb: newQRCodeVerb });
    }

    return (
        <div className='qr-code-verb-options'>
            {Constants.qrCodeVerbs.map((verb, index) => (
                <label>
                    <input
                        className='qr-code-verb-radio-button'
                        key={index}
                        type='radio'
                        value={verb}
                        checked={updatedUserProfileData.qrCodeVerb === verb}
                        onChange={onChange}
                    />
                    {verb}
                </label>
            ))}
        </div>
    );
}

export default QRCodeVerbEdit;

import HelperMethods from '../../helpers/HelperMethods';
import { SocialMediaLinks } from '../../types/types';
import ProfileUpdateData from './ProfileUpdateData';

class PaymentProfileUpdateData extends ProfileUpdateData {
    suggestedPayValues: number[];
    thankyouMessage: string;
    socialMediaLinks: SocialMediaLinks;

    constructor(
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        suggestedPayValues: number[],
        thankyouMessage: string,
        socialMediaLinks: any,
        linkedAccountEmailAddresses: string[]
    ) {
        super(displayName, base64ProfileImage, qrCodeVerb, linkedAccountEmailAddresses);

        this.suggestedPayValues = HelperMethods.getValidButtonValues(suggestedPayValues);
        this.thankyouMessage = thankyouMessage;
        this.socialMediaLinks = HelperMethods.getValidSocialMediaLinks(socialMediaLinks);
    }
}

export default PaymentProfileUpdateData;

import './chooseaccounttypepage.scss';
import 'react-phone-input-2/lib/bootstrap.css';

import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
} from 'react';

import { FaCircleArrowRight } from 'react-icons/fa6';

import DefaultNavbar from '../../components/navbar/DefaultNavbar';
import NavigationArrowButton from '../../components/NavigationArrowButton';
import PageContent from '../../components/PageContent';
import HelperMethods from '../../helpers/HelperMethods';
import { AccountType } from './types/types';

interface AccountTypeCardProps {
  accountType: AccountType;
  description: string;
  comingSoon: boolean;
  setSelectedAccountType: Dispatch<SetStateAction<AccountType|null>>;
}

const AccountTypeCard = (props: AccountTypeCardProps): ReactElement => {
  const {accountType, description, comingSoon, setSelectedAccountType} = props;

  return (
    <div className='account-card-button-wrapper'>
      <button 
        className={`account-type-card-button ${comingSoon ? 'disabled' : ''}`}
        onClick={() => setSelectedAccountType(accountType)}
      >
        <div className='account-type-card-text-section'>
          <h2>{accountType.displayName}</h2>
          <p>{description}</p>
        </div>
        <div className='account-type-card-arrow-section'>
          <FaCircleArrowRight className='account-type-card-arrow' />
        </div>
      </button>
      { comingSoon &&
          <div className='coming-soon-overlay'>
            <h3>COMING SOON</h3>
          </div>
        }
    </div>
    

  );
}

interface AccountTypeCardData {
  accountType: AccountType;
  description: string;
  comingSoon: boolean;
}

const accountTypeList: AccountTypeCardData[] = [
  {
    accountType: AccountType.INDIVIDUAL,
    description: 'For individuals who want to receive payments to their bank account',
    comingSoon: false,
  },
  {
    accountType: AccountType.AGGREGATOR,
    description: 'For businesses who want to display Payment Accounts. For example hair salons or tattoo parlours, where the individuals themselves collect the tips! Cannot receive payments directly.',
    comingSoon: false
  },
  {
    accountType: AccountType.COMPANY,
    description: 'You have a registered company, and you are a Personal of Significant Control or Director of the company',
    comingSoon: true
  }
];

interface ChooseAccountTypePageProps {
  setSelectedAccountType: Dispatch<SetStateAction<AccountType|null>>;
}

const ChooseAccountTypePage = (props: ChooseAccountTypePageProps): ReactElement => {
const { setSelectedAccountType } = props;

  return (
    <>
      <DefaultNavbar/>
      <PageContent>
        <h1 className='choose-acount-type-title-text'>Account type</h1>
        {accountTypeList.map((item, index) => (
          <div key={index}>
            <AccountTypeCard
              accountType={item.accountType}
              description={item.description}
              comingSoon={item.comingSoon}
              setSelectedAccountType={setSelectedAccountType}
            />
          </div>
        ))}
        <NavigationArrowButton
          onClick={() => HelperMethods.signOut()}
          bottomAnchored='bottom-left'
          backArrow={true}
        />
      </PageContent>
    </>
  );
}

export default ChooseAccountTypePage;

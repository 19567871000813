import './vieweditprofilepage.scss';

import { ReactElement } from 'react';

import AccountUserProfileData
  from '../../classes/getprofilefromtoken/AccountUserProfileData';
import AggregatorAccountUserProfileData
  from '../../classes/getprofilefromtoken/AggregatorAccountUserProfileData';
import PaymentAccountUserProfileData
  from '../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import AggregatorViewEditProfilePage from './AggregatorViewEditProfilePage';
import PaymentViewEditProfilePage from './PaymentViewEditProfilePage';

const ViewEditProfilePage = (): ReactElement => {
    const userProfileData: AccountUserProfileData | null = useAppSelector((state: RootState) => state.userData.userProfileData);

    if (userProfileData instanceof PaymentAccountUserProfileData) {
        return <PaymentViewEditProfilePage userProfileData={userProfileData}/>
    } else if (userProfileData instanceof AggregatorAccountUserProfileData) {
        return <AggregatorViewEditProfilePage userProfileData={userProfileData}/>;
    } else {
        return <></>;
    }
}

export default ViewEditProfilePage;

import './settings.scss';

import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { FaChevronRight } from 'react-icons/fa6';
import { IoExit } from 'react-icons/io5';

import AccountUserProfileData
  from '../../classes/getprofilefromtoken/AccountUserProfileData';
import LoadingIcon from '../../components/LoadingIcon';
import PageOverlay from '../../components/PageOverlay';
import HelperMethods from '../../helpers/HelperMethods';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import AboutPage from '../websitecontent/about/AboutPage';
import ContactPage from '../websitecontent/contact/ContactPage';
import FAQsPage from '../websitecontent/faqs/FAQsPage';
import AcceptableUsePolicyPage
  from '../websitecontent/legal/AcceptableUsePolicyPage';
import CookiePolicyPage from '../websitecontent/legal/CookiePolicyPage';
import DisclaimerPolicyPage from '../websitecontent/legal/DisclaimerPolicyPage';
import PrivacyPolicyPage from '../websitecontent/legal/PrivacyPolicyPage';
import RefundPolicyPage from '../websitecontent/legal/RefundPolicyPage';
import TermsAndConditionsPolicyPage
  from '../websitecontent/legal/TermsAndConditionsPolicyPage';
import TippingTipsPage from '../websitecontent/tippingtips/TippingTipsPage';

interface SectionItem {
    name: string;
    description?: string;
    icon: ReactElement;
    onClick?: () => void;
}

interface SettingsSectionProps {
    title: string;
    sectionItems: SectionItem[];
}

const SettingsSection = (props: SettingsSectionProps): ReactElement => {
    const { title, sectionItems } = props;

    return (
        <div className='settings-section'>
            <h2 className='settings-section-title'>{title}</h2>
            { sectionItems.map((item: SectionItem, index: number) => (
                <div 
                    key={index}
                    className={`settings-section-item ${index === sectionItems.length-1 ? 'last-item' : ''} ${item.onClick ? 'shows-content' : ''}`}
                    onClick={item.onClick}
                >
                    <div className='settings-section-item-text'>
                        <p className='settings-section-item-title'>{item.name}</p>
                        { item.description &&
                            <p className='settings-section-item-description'>{item.description}</p>
                        }
                    </div>
                    { item.icon &&
                        item.icon
                    }
                </div>
            ))}
        </div>
    );
}

const Settings = (): ReactElement => {
    const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
    const [overlayContent, setOverlayContent] = useState<ReactElement>(<></>);

    const reduxUserProfileData: AccountUserProfileData | null = useAppSelector((state: RootState) => state.userData.userProfileData);

    useEffect(() => {
        // Toggle the no-scroll class on the body element when the overlay is visible
        if (overlayVisible) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Clean up by removing the class when the component unmounts or overlay becomes invisible
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [overlayVisible]);

    const displayPageOverlay = (content: ReactElement): void => {
        setOverlayContent(content);
        setOverlayVisible(true);
    }

    if (!reduxUserProfileData) {
        return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
    }
    
    return (
        <div className='settings-page-wrapper'>
            <SettingsSection
                title='About'
                sectionItems={[
                    {
                        name: 'About Us',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<AboutPage/>)
                    },
                    {
                        name: 'FAQs',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<FAQsPage/>)
                    },
                    {
                        name: 'Contact',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<ContactPage/>)
                    }
                ]}
            />
            <SettingsSection
                title='Info'
                sectionItems={[
                    {
                        name: 'Tipping Tips',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<TippingTipsPage/>)
                    },
                    // {
                    //     name: 'Tax on Tips',
                    //     icon: <FaChevronRight className='settings-section-item-icon'/>,
                    //     onClick: () => displayPageOverlay(<TaxOnTipsPage/>)
                    // }
                ]}
            />
            <SettingsSection
                title='Legal'
                sectionItems={[
                    {
                        name: 'Terms and Conditions',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<TermsAndConditionsPolicyPage/>)
                    },
                    {
                        name: 'Privacy Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<PrivacyPolicyPage/>)    
                    },
                    {
                        name: 'Cookie Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<CookiePolicyPage/>)    
                    },
                    {
                        name: 'Acceptable Use Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<AcceptableUsePolicyPage/>)    
                    },
                    {
                        name: 'Disclaimer Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<DisclaimerPolicyPage/>)    
                    },
                    {
                        name: 'Refund Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<RefundPolicyPage/>) 
                    }
                ]}
            />
            <SettingsSection title='Account Actions'
                sectionItems={[
                    {
                        name: 'Sign-out',
                        icon: <IoExit className='settings-section-item-icon'/>,
                        onClick: () => HelperMethods.signOut()
                    }
                ]}
            />
            { overlayVisible &&
                <PageOverlay
                    content={overlayContent}
                    onClose={() => setOverlayVisible(false)}
                />
            }
        </div>
    );
}

export default Settings;

import './enteremailaddresspage.scss';

import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { IoMdMail } from 'react-icons/io';
import { useNavigate } from 'react-router';

import ApiResponse from '../../classes/ApiResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/getaccountstatefromtoken/GetAccountStateFromTokenResponse';
import InitiateAuthRequest
  from '../../classes/initiateauthrequest/InitiateAuthRequest';
import InitiateAuthResponse
  from '../../classes/initiateauthrequest/InitiateAuthResponse';
import ErrorMessage from '../../components/ErrorMessage';
import InvalidInputErrorMessage
  from '../../components/InvalidInputErrorMessage';
import LoadingIcon from '../../components/LoadingIcon';
import NavigationArrowButton from '../../components/NavigationArrowButton';
import PageContent from '../../components/PageContent';
import TextLink from '../../components/TextLink';
import Constants from '../../configuration/constants';

const invalidEmailAddress = 'invalid-email-address';

type EnterEmailAddressPageFormData = {
    emailAddress: string;
}

interface EnterEmailAddressPageProps {
    setShowEnterOTPPage: (state: boolean) => void;
    setEmailAddress: (emailAddress: string) => void;
}

const EnterEmailAddressPage = (props: EnterEmailAddressPageProps): ReactElement => {
    const { setShowEnterOTPPage, setEmailAddress } = props;

    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');
    const [loadingPage, setLoadingPage] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                null,
                true
            );
        };

        if (loadingPage) {
            getAccountStateFromToken().then(response => {
                if (response && !(response instanceof AxiosError)) {
                    if (!response.accountCreated) {
                        navigate(Constants.createAccountPagePath);
                    } else if (response.accountCreated && !response.stripeOnboardingCompleted) {
                        navigate(Constants.connectedAccountPagePath);
                    } else {
                        navigate(Constants.homePagePath);
                    }
                }
            });
            setLoadingPage(false);
        }
    }, [loadingPage, navigate]);

    const form = useForm<EnterEmailAddressPageFormData>({
        defaultValues: {
            emailAddress: ''
        },
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });
    const { handleSubmit, formState, register } = form;
    const { errors, isValid, isSubmitting } = formState;

    const onSubmitEmailAddress = async (formData: EnterEmailAddressPageFormData) => {
        setCustomerErrorMessage('');
        const initiateAuthRequest = new InitiateAuthRequest(formData.emailAddress);
        const response: InitiateAuthResponse|null = await ApiResponse.getApiResponse(
            Constants.initiateAuthRequestEndpoint,
            initiateAuthRequest,
            InitiateAuthResponse,
            null,
            setCustomerErrorMessage,
            false
        );
        
        if (!response || !response.authSessionId) {
            return;
        } else {
            Cookies.set(Constants.authSessionIdTokenName, response.authSessionId, { 
                path: '/',
                secure: true, // If testing using localhost on Safari, this must be set to false
                sameSite: 'strict',
                expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // 1 year from now
            });
            setShowEnterOTPPage(true);
            setEmailAddress(formData.emailAddress);
        }
    }

    if (loadingPage) {
        return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
    }

    return (
        <PageContent>
            <div className='email-icon-wrapper'><IoMdMail className='email-icon'/></div>
            <h1 className='whats-your-email-title'>What's your email address?</h1>
            <form onSubmit={handleSubmit(onSubmitEmailAddress)}>
                <input 
                    className={'email-address-input' + (errors.emailAddress ? ' ' + invalidEmailAddress : '')}
                    type='email'
                    id='emailAddress'
                    placeholder='example@email.com'
                    spellCheck={false}
                    {...register('emailAddress', {
                        required: 'Please enter your email',
                        pattern: {
                            value: Constants.regexPatternEmailAddress,
                            message: 'Invalid email format'
                        },
                        validate: {
                            leadingOrTrailingSpace: (fieldValue) => {
                                return fieldValue.trim() === fieldValue || 'Email address cannot start or end with a space'
                            }
                        }
                    })}
                    disabled={isSubmitting}
                />
                { errors.emailAddress?.message && <InvalidInputErrorMessage errorMessage={errors.emailAddress.message}/> }
                <p className='verification-code-message'>By entering your email address you are agreeing to our <TextLink text='Terms and Conditions' href={`${Constants.frontendURL}${Constants.termsAndConditionsPolicyPagePath}`} colour='grey-mid'/>, <TextLink text='Privacy Policy' href={`${Constants.frontendURL}${Constants.privacyPolicyPagePath}`} colour='grey-mid'/> and <TextLink text='Cookie Policy' href={`${Constants.frontendURL}${Constants.cookiePolicyPagePath}`} colour='grey-mid'/>.</p>
                { customerErrorMessage &&  <ErrorMessage errorMessage={customerErrorMessage}/> }
                { isSubmitting ? <LoadingIcon/>
                    :
                    <NavigationArrowButton
                        disabled={!isValid}
                        bottomAnchored='bottom-right'
                        onClick={handleSubmit(onSubmitEmailAddress)}
                    />
                }
            </form>
        </PageContent>
    );
}

export default EnterEmailAddressPage;

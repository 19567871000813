import './paymentpageviewedittab.scss';

import { ReactElement } from 'react';

import PaymentAccountUserProfileData
  from '../../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import LoadingIcon from '../../components/LoadingIcon';
import HelperMethods from '../../helpers/HelperMethods';
import PaymentPageProfile from '../payment/PaymentPageProfile';

interface PaymentPageViewEditTabProps {
  userProfileData: PaymentAccountUserProfileData;
}

const PaymentPageViewEditTab = (props: PaymentPageViewEditTabProps): ReactElement => {
  const {userProfileData} = props;
  
  if (!userProfileData) {
    return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
  }

  return (
    <div className='payment-page-preview-wrapper'>
      <div className='payment-page-preview'>
        <PaymentPageProfile
          paymentProfileDisplayData={HelperMethods.getPaymentProfileDisplayDataFromPaymentAccountUserProfileData(userProfileData)}
          paymentAccountUserProfileData={userProfileData}
          isPreview={true}
        />
      </div>
    </div>
  );
}

export default PaymentPageViewEditTab;

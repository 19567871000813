import { ReactElement } from 'react';

import ContactUs from '../../../components/ContactUs';
import PageContent from '../../../components/PageContent';
import Constants from '../../../configuration/constants';

const TermsAndConditionsPolicyPage = (): ReactElement => {
    return (
        <PageContent alignItems='left' fullWidth>
            <h1>Terms and Conditions</h1>

            <p>These terms and conditions (“Agreement”) set forth the general terms and conditins of your use of the {Constants.frontendURL} website (“Website”) and any of their related products and services (collectively, “Services”). These terms and conditions will be referred to collectively as the/this “Agreement”).</p>
            <p>This Agreement is legally binding between you (“User”, “you” or “your”) and {Constants.registeredCompanyName} (doing business as {Constants.tradingName}) ({Constants.tradingName}, “we”, “us” or “our”). By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms “User”, “you” or “your” shall refer to such entity.</p>
            <p>If you do not have such authority, or if you do not agree with and accept the terms of this Agreement, you must not accept this Agreement and may not access and use the Services. If you proceed to commence use of the Services, you will be deemed to have accepted the terms of this Agreement in full. You acknowledge that this Agreement is a contract between you and {Constants.tradingName}, even though it is electronic and is not physically signed by you, and it governs your use of the Services.</p>

            <h2>Who we are</h2>
            <p>{Constants.tradingName} is a cashless payment platform, where tips and/or cashless payments are paid quickly, efficiently and in a fair way. The Website and Services are owned by {Constants.registeredCompanyName}, company number {Constants.registeredCompanyNumber}, a private company limited by shares, whose registered address is {Constants.registeredCompanyAddress}.</p>

            <h2>Interpretation</h2>
            <p>A reference to a statute or statutory provision is a reference to is as amended, extended or re–enacted from time to time. A reference to a statute or statutory provision includes any subordinate legislation, made from time to time under that statute or statutory provision and any ruling, directive or similar determination made under such statutory provision or subordinate legislation. Any words following the terms "including", "include", "in particular", "for example" or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms. A reference to writing or written includes email.</p>

            <h2>When these Terms apply</h2>
            <p>These terms apply when you create an account to use the {Constants.tradingName} Website and/or use any of the Services and throughout the entirety of your use of any of them.</p>
        
            <h2>Our appointment as your Commercial Agent</h2>
            <p>By entering this Agreement you grant {Constants.tradingName} authority to remit tips and/or cashless payments to your business or to your employees.</p>
            <p>You grant us authority to enter into transactions to collect tips and/or cashless payments on your behalf.</p>
            <p>We will be liable to you if we collect tips and/or cashless payments on your behalf or your employees’ behalf and fail to remit them to you or your employees.</p>

            <h2>Account Membership</h2>
            <p>By using the Services and agreeing to this Agreement you warrant and represent that you are at least 18 years of age.</p>
            <p>You are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it.</p>
            <p>You must immediately notify us of any unauthorised uses of your account or any other breaches of security.</p>
            <p>We may monitor and review new accounts before you may sign in and start using the Services.</p>

            <h2>Duration of Agreement</h2>
            <p>This Agreement commences on the date upon which you consent to the Terms and Conditions or upon the date upon which you first use any of the Services (whichever comes first) and continues until either of us serves a notice to terminate the Agreement in accordance with these terms.</p>
            
            <h2>Cancellation and Termination</h2>
            <p>Users wanting to terminate their account at any time can <ContactUs/> to do so, subject to a 30 day notice period and asking us to delete your account.</p>
            <p>If you terminate your {Constants.tradingName} account, you will no longer be able to collect tips and/or cashless payments. We will remit any payments owed to you, minus any amount owed to us, within 90 days of your request to delete your account.</p>
            <p>{Constants.tradingName} reserves the right to terminate or suspend accounts at any time and with immediate effect or at a time determined by {Constants.tradingName} for breaches of this Agreement by giving written notice to you, including in the event that you provide us with false or misleading information in connection with the Services or if you use the Service for purposes not permitted hereunder.</p>
            <p>We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill.</p>
            <p>Without prejudice to the foregoing, we may (in our absolute discretion) cancel, withdraw or suspend the Services, or any of them (and accordingly terminate your account) at any time for any reason including (without limitation) any business reason, technical reason or legal reason.</p>
            <p>If we delete your account for any of the foregoing reasons, you may not re– register for our Services without our prior written consent. We may block your email address and Internet protocol address to prevent further registration.</p>
            <p>Some of the terms of this Agreement will continue to be enforceable, even after termination including confidentiality obligations, warranties, disclaimers, and limitations of liability.</p>

            <h2>Billing and Payments</h2>
            <p>You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after the free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period, if applicable).</p>
            <p>If auto–renewal is enabled for the Services you have subscribed for, you will be charged automatically in accordance with the term you selected. If, in our judgment, your purchase constitutes a high–risk transaction, we will require you to provide us with a copy of your valid government–issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time.. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e–mail and/or billing address/phone number provided at the time the order was made.</p>

            <h2>Stripe</h2>
            <p>Stripe, Inc ("Stripe") is the payment processor used by {Constants.tradingName} to process payments to you through our Services. {Constants.tradingName} does not hold your tips or cashless payments. Your tips and cashless payments are held in Stripe's account. Stripe's Terms and Conditions apply to payments made through our Services. {Constants.tradingName}, its affiliates, directors, officers, employees, agents, suppliers or licensors disclaim all liability for any damage, howsoever causes, related to the actions of Stripe and you shall, to the maximum extent permitted by applicable law, not have any claim against {Constants.tradingName} arising from any of Stripe's actions in respect of any of the Services.</p>

            <h2>Accuracy of Information</h2>
            <p>Occasionally there may be information on the Services that contains typographical errors, inaccuracies or omissions that may relate to promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Services or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Services including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Services should be taken to indicate that all information on the Services or Services has been modified or updated.</p>
            
            <h2>Third Party Services</h2>
            <p>If you decide to enable, access or use third party services, your access and use of such services are governed solely by the terms and conditions of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services.</p>
            <p>You irrevocably waive any claim against {Constants.tradingName} with respect to such other services and agree that {Constants.tradingName} shall not be liable for any damage or loss caused, or alleged to be caused, by or in connection with your enablement, access or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services. You may be required to register for or log into such other services on their respective platforms. By enabling any other services, you are expressly permitting {Constants.tradingName} to disclose your data as necessary to facilitate the use or enablement of such other service.</p>
        
            <h2>Links to other resources</h2>
            <p>Although the Services may link to other resources (such as websites, mobile applications, etc.), we are not – directly or indirectly – implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. Some of the links on the Services may be "affiliate links". This means if you click on the link and purchase an item, {Constants.tradingName} will (or may) receive an affiliate commission. We are not responsible for examining or evaluating and we do not warrant the offerings of any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Services. Your linking to any other off–site resources is at your own risk.</p>
            
            <h2>Terms subject to change</h2>
            <p>Please note that we may change, modify, add or remove sections of this Agreement from time to time. We will post any changes to our Website by updating this Agreement.</p>

            <h2>Data protection</h2>
            <p>All rights, titles and interests in your personal data held on the Website or Mobile Application are yours.</p>

            <h2>Intellectual Property</h2>
            <p>"Intellectual Property Rights" means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world.</p>
            <p>This Agreement does not transfer to you any intellectual property owned by {Constants.tradingName} or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with {Constants.tradingName} or the relevant third party/ies. All trademarks, service marks, graphics and logos used in connection with the Services, are trademarks or registered trademarks of {Constants.registeredCompanyName} or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Services may be the trademarks of other third parties. Your use of the Services grants you no right or license to reproduce or otherwise use any of {Constants.tradingName} or third party trademarks.</p>
        
            <h2>Disclaimer of Warranty</h2>
            <p>You agree that the Services are provided on an “as is” and “as available” basis and that your use of the Services is solely at your own risk. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non–infringement. We make no warranty that the Services will meet your requirements, or that the Services will be uninterrupted, timely, secure, or error–free; nor do we make any warranty as to the results that may be obtained from the use of the Services or as to the accuracy or reliability of any information obtained through the Services or that defects in the Services will be corrected.</p>
            <p>You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Services is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Services or any transactions entered into through the Services unless stated otherwise. No advice or information, whether oral or written, obtained by you from us or through the Services shall create any warranty not expressly made herein.</p>

            <h2>Limitation of Liability</h2>
            <p>In no event will {Constants.tradingName}, its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages.</p>
            <p>The aggregate liability of {Constants.tradingName} and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount no greater than one Great British Pound or any amount(s) actually paid in cash by you to {Constants.tradingName} in the one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.</p>
            
            <h2>Indemnification</h2>
            <p>You agree to indemnify and hold {Constants.tradingName} and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable solicitors‘ legal fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to any content provided by you to us, your use of the Services or any wilful misconduct on your part.</p>

            <h2>Cannot Guarantee Uninterrupted Service</h2>
            <p>While we do not guarantee that our Services will always be available or be uninterrupted or error free, we will use reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner which minimises errors and interruptions.</p>
            <p>We will perform updates to the Services in a professional and workmanlike manner. The Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance or because of other causes beyond our reasonable control.</p>

            <h2>Your responsibilities under these Terms</h2>
            <p>In order to use the Services you must:</p>
            <ul>
                <li>Be 18 years old or over and have an address in the United Kingdom.</li>
                <li>Ensure that all information and details (in particular your email address and bank account details) you provide to us are true, accurate, complete and current.</li>
                <li>Promptly update us with any changes to such information.</li>
                <li>Comply with all applicable laws, rules, regulations and court orders.</li>
                <li>Adhere to all our policies.</li>
            </ul>

            <h2>Prohibited Use</h2>
            <p>In addition to other terms as set forth in the Agreement, you are prohibited from using the Services:</p>
            <ul>
                <li>For any unlawful purpose.</li>
                <li>To solicit others to perform or participate in any unlawful acts.</li>
                <li>To solicit others to perform or participate in any unlawful acts.</li>
                <li>To violate any international, federal, provincial, common or state regulations, rules, laws, or local ordinances.</li>
                <li>To infringe upon or violate our intellectual property rights or the intellectual property rights of others.</li>
                <li>To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability.</li>
                <li>To submit false or misleading information.</li>
                <li>To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Services, third party products and services, or the Internet.</li>
                <li>To spam, phish, pharm, pretext, spider, crawl, or scrape.</li>
                <li>For any obscene or immoral purpose.</li>
                <li>To interfere with or circumvent the security features of the Services, third party products and services, or the Internet.We reserve the right to terminate your use of the Services for violating any of the prohibited uses and, in some cases, cite your violation(s) as grounds for litigation.</li>
            </ul>

            <h2>Advertisments</h2>
            <p>During your use of the Services, you may encounter promotions of advertisers or sponsors showing their goods or services. Any such activity, and any terms, conditions, warranties or representations associated with such activity, is solely between you and the applicable third party. {Constants.tradingName} shall have no liability, obligation or responsibility for any such correspondence, purchase or promotion between you and any such third party.</p>
            <p>f you have a complaint, you may <ContactUs/> and describe in as much as detail as possible the nature of the complaint, including when the issue happened and who you have been dealing with in our team.</p>
            <p>You will receive an acknowledgement email from us within 7 working days.</p>
            <p>A senior member of our organisation will review the complaint and contact you to organise a call with you, or write back to you to discuss the matter in greater detail with you, within 7 working days from the acknowledgement email. This initial response may offer a resolution or may begin a dialogue in an attempt to reach the best possible outcome.</p>
            <p>A final resolution or decision on the matter of your complaint will be communicated to you within 7 working days of the acknowledgement email.</p>

            <h2>Complaints</h2>
            <p>All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable.</p>
            <p>If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.</p>
            
            <h2>Assignment</h2>
            <p>You may not assign, resell, sub–license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void.</p>
            <p>We are free to assign any of our rights or obligations hereunder, in whole or in part, to any third party without consent, for any reason, including as part of the sale of all or substantially all of its assets or stock or as part of a merger.</p>
            
            <h2>Entire Agreement</h2>
            <p>This Agreement, together with the acts of our duly authorised agents constitute the entire agreement between the parties and supersede all other agreements, statements, letters and other arrangements between the parties in relation to the subject matter hereof. Each party acknowledges that it has not relied on or been induced to enter this Agreement by a representation other than those expressly set out in these Terms. This clause does not affect a party’s liability in respect of a fraudulent misrepresentation.</p>

            <h2>Notices</h2>
            <p>All notices under these Terms will be deemed to have been duly given when received, or if transmitted by email, the day after it is sent.</p>

            <h2>Governing Law</h2>
            <p>This Agreement and any dispute or claim arising out of or in connection with them or their subject matter, formation, existence, negotiation, validity, termination or enforceability (including non–contractual obligations, disputes or claims) (“Dispute”) shall be governed by and construed in accordance with the laws of England and Wales.</p>

            <h2>Jurisdiction</h2>
            <p>The courts of England and Wales shall have exclusive jurisdiction to settle any dispute.</p>

            <h2>Acceptance of these Terms</h2>
            <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the services, you agree to be bound by this agreement. If you do not agree to abide by the terms of this agreement, you are not authorized to access or use the services.</p>

            <h2>Contact us</h2>
            <p>If you have any questions or concerns related to this policy please <ContactUs/>.</p>
            
            <p>This policy was last updated November 13, 2024.</p>
        </PageContent>
    );
}

export default TermsAndConditionsPolicyPage;

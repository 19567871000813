class PayoutSchedule {
    static type: string = 'PayoutSchedule';
    
    interval: PayoutScheduleInterval;
    weeklyAnchor: PayoutScheduleWeeklyAnchor;
    monthlyAnchor: number | null;
      
    constructor(interval: PayoutScheduleInterval, weeklyAnchor: PayoutScheduleWeeklyAnchor, monthlyAnchor: number | null) {
        this.interval = interval;
        this.weeklyAnchor = weeklyAnchor;
        this.monthlyAnchor = monthlyAnchor;
    }
}

export default PayoutSchedule;

export type PayoutScheduleInterval = 'daily' | 'weekly' | 'monthly';

export type PayoutScheduleWeeklyAnchor = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | null;

import './navigationarrowbutton.scss';

import { ReactElement } from 'react';

import {
  FaCircleArrowLeft,
  FaCircleArrowRight,
} from 'react-icons/fa6';

interface NavigationArrowButtonProps {
    onClick: any;
    disabled?: boolean;
    backArrow?: boolean;
    bottomAnchored?: 'bottom-left' | 'bottom-right';
    buttonType?: 'submit';
}

const NavigationArrowButton = (props: NavigationArrowButtonProps): ReactElement => {
    const { onClick, disabled, backArrow, bottomAnchored, buttonType } = props;

    return (
        <button
            className={`navigation-arrow-button-wrapper ${bottomAnchored ? bottomAnchored : ''}`}
            disabled={disabled}
            onClick={() => onClick()}
            type={buttonType}
        >
            { backArrow ?
                <FaCircleArrowLeft className={`navigation-arrow-button back ${disabled ? 'disabled' : ''}`}/>
                :
                <FaCircleArrowRight className={`navigation-arrow-button ${disabled ? 'disabled' : ''}`}/>
            }
        </button>
    );
}

export default NavigationArrowButton;

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import ApiResponse from '../classes/ApiResponse';
import CreateStripeAccountSessionResponse
  from '../classes/createstripeaccountsession/CreateStripeAccountSessionResponse';
import AccountUserProfileData
  from '../classes/getprofilefromtoken/AccountUserProfileData';
import GetProfileFromTokenResponse
  from '../classes/getprofilefromtoken/GetProfileFromTokenResponse';
import Constants from '../configuration/constants';
import { RootState } from './store';

interface AccountUserProfileDataState {
    userProfileData: AccountUserProfileData | null;
    clientSecret: string | null;
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: AccountUserProfileDataState = {
    userProfileData: null,
    clientSecret: null,
    status: 'idle',
    error: null,
};

export const fetchUserProfileData = createAsyncThunk<AccountUserProfileData | null, void>(
    'userData/fetchUserProfileData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await ApiResponse.getApiResponse(
                Constants.getProfileFromTokenEndpoint,
                null,
                GetProfileFromTokenResponse,
                null,
                null,
                true
            );
            
            if (!response || !response.profileFromToken) {
                return null;
            }

            return response.profileFromToken;
        } catch (error) {
            return rejectWithValue('Failed to fetch user profile data');
        }
    }
);
  
export const fetchClientSecret = createAsyncThunk<string | null, void>(
    'userData/fetchClientSecret',
    async (_, { rejectWithValue }) => {
        try {
            const response = await ApiResponse.getApiResponse(
                Constants.createStripeAccountSessionEndpoint,
                null,
                CreateStripeAccountSessionResponse,
                null,
                null,
                true
            );
            
            if (!response || !response.clientSecret) {
                return null;
            }

            return response.clientSecret;
        } catch (error) {
            return rejectWithValue('Failed to fetch client secret');
        }
    }
);
  
const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        updateUserProfileData: (state, action: PayloadAction<AccountUserProfileData>) => {
            state.userProfileData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfileData.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchUserProfileData.fulfilled, (state, action: PayloadAction<AccountUserProfileData | null>) => {
                state.userProfileData = action.payload;
                state.status = 'idle';
            })
            .addCase(fetchUserProfileData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(fetchClientSecret.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchClientSecret.fulfilled, (state, action: PayloadAction<string | null>) => {
                state.clientSecret = action.payload;
                state.status = 'idle';
            })
            .addCase(fetchClientSecret.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const selectUserProfileData = (state: RootState) => state.userData.userProfileData;
export const selectClientSecret = (state: RootState) => state.userData.clientSecret;

export const { updateUserProfileData } = userDataSlice.actions;

export default userDataSlice.reducer;
  
import { AccountType } from '../../pages/createaccount/types/types';
import AccountUserProfileData from './AccountUserProfileData';
import LinkedAccountData from './LinkedAccountData';

class AggregatorAccountUserProfileData extends AccountUserProfileData {
    constructor(
        username: string,
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        linkedAccountsData: LinkedAccountData[]
    ) {
        super(
            username,
            displayName,
            base64ProfileImage,
            qrCodeVerb,
            AccountType.AGGREGATOR.value,
            linkedAccountsData
        );
    }
}

export default AggregatorAccountUserProfileData;

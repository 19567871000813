import HelperMethods from '../../helpers/HelperMethods';
import { Address } from '../../pages/createaccount/types/types';
import AccountCreateData from './AccountCreateData';

class PaymentAccountCreateData extends AccountCreateData {
    currency: string;
    url: string;

    constructor(
        username: string,
        displayName: string,
        firstName: string,
        lastName: string,
        industryType: string,
        accountType: string,
        country: string,
        address: Address,
        dob: string,
        phoneNumber: string,
        currency: string,
        url: string
    ) {
        super(
            username,
            displayName,
            firstName,
            lastName,
            industryType,
            accountType,
            country,
            address,
            dob,
            phoneNumber
        );
        this.currency = currency.toLowerCase();
        this.url = HelperMethods.getValidUrl(url);
    }
}

export default PaymentAccountCreateData;

import './defaultnavbar.scss';

import React, { ReactElement } from 'react';

import Constants from '../../configuration/constants';

const DefaultNavbar = (): ReactElement => {
  return (
    <div className='default-navbar'>
      <img className='default-navbar-logo' src={require(`../../assets/images/logos/${Constants.secondaryLogoWhite}`)} alt='logo'/>
    </div>
  );
};

export default DefaultNavbar;

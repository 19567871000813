import './linkedaccountsedit.scss';

import React, {
  ReactElement,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import {
  IoAddCircle,
  IoCloseCircle,
} from 'react-icons/io5';

import ApiResponse from '../../../classes/ApiResponse';
import GetProfileDisplayDataRequest
  from '../../../classes/getprofiledisplaydata/GetProfileDisplayDataRequest';
import GetProfileDisplayDataResponse
  from '../../../classes/getprofiledisplaydata/GetProfileDisplayDataResponse';
import {
  IdentifierType,
} from '../../../classes/getprofiledisplaydata/IdentifierType';
import PaymentProfileDisplayData
  from '../../../classes/getprofiledisplaydata/PaymentProfileDisplayData';
import AccountUserProfileData
  from '../../../classes/getprofilefromtoken/AccountUserProfileData';
import AggregatorAccountUserProfileData
  from '../../../classes/getprofilefromtoken/AggregatorAccountUserProfileData';
import LinkedAccountData
  from '../../../classes/getprofilefromtoken/LinkedAccountData';
import Constants from '../../../configuration/constants';
import { LinkedProfileCard } from '../../payment/AggregatorPageProfile';
import { EditAccountProfileModalBodyProps } from './types';

const LinkedAccountsEdit = (props: EditAccountProfileModalBodyProps<AccountUserProfileData>): ReactElement => {
    const { updatedUserProfileData, updateAccountUserProfileData, setHasMadeEdit, setCustomerErrorMessage } = props;

    const [currentEmailAddress, setCurrentEmailAddress] = useState<string>('');
    const [currentLinkedAccountsData, setCurrentLinkedAccountsData] = useState<LinkedAccountData[]>(updatedUserProfileData.linkedAccountsData);
  
    const handleCurrentLinkedAccountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCurrentEmailAddress(event.target.value);
    };
  
    const handleLinkedAccountInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            handleAddLinkedAccount();
        }
    };
  
    const handleAddLinkedAccount = async (): Promise<void> => {
        if (!currentEmailAddress || !isValidLinkedAccount()) {
            return;
        }

        const response = await ApiResponse.getApiResponse(
            Constants.getProfileDisplayDataEndpoint,
            new GetProfileDisplayDataRequest(currentEmailAddress, IdentifierType.EMAIL_ADDRESS),
            GetProfileDisplayDataResponse,
            null,
            null,
            true
        );

        if (!response || response instanceof AxiosError || !response.profileDisplayData) {
            if (setCustomerErrorMessage) {
                setCustomerErrorMessage(`${currentEmailAddress} does not link to a valid account`);
            }
            return;
        } else if (!(response.profileDisplayData instanceof PaymentProfileDisplayData)) {
            if (setCustomerErrorMessage) {
                setCustomerErrorMessage('Linked accounts must be Payment accounts');
            }
            return;
        }

        if (setCustomerErrorMessage) {
            setCustomerErrorMessage('');
        }

        const newLinkedAccountData = new LinkedAccountData(currentEmailAddress, response.profileDisplayData);

        const newCurrentLinkedAccountsData = [...currentLinkedAccountsData, newLinkedAccountData];
        setCurrentLinkedAccountsData(newCurrentLinkedAccountsData);

        setHasMadeEdit(true);
        setCurrentEmailAddress('');
        if (setCustomerErrorMessage) {
            setCustomerErrorMessage('');
        }
        
        updateAccountUserProfileData(updatedUserProfileData, { linkedAccountsData: newCurrentLinkedAccountsData });
    };
  
    const isValidLinkedAccount = (): boolean => {        
        return (
            currentEmailAddress != null
            && currentEmailAddress != undefined
            && currentEmailAddress.trim() !== ''
            && !currentLinkedAccountsData.map(data => data.emailAddress).includes(currentEmailAddress)
            && Constants.regexPatternEmailAddress.test(currentEmailAddress)
        );
    };
  
    const handleRemoveLinkedAccount = (linkedAccountData: LinkedAccountData): void => {
        setHasMadeEdit(true);
        const newLinkedAccounts = currentLinkedAccountsData.filter(item => item.emailAddress !== linkedAccountData.emailAddress);
        setCurrentLinkedAccountsData(newLinkedAccounts);

        updateAccountUserProfileData(updatedUserProfileData, { linkedAccountsData: newLinkedAccounts });
    };
    
    return (
        <>
            { updatedUserProfileData instanceof AggregatorAccountUserProfileData &&
                <div className='aggregated-accounts-input-section'>
                    <input
                        className='form-item-text-input'
                        type='email'
                        placeholder='example@email.com'
                        value={currentEmailAddress}
                        onChange={handleCurrentLinkedAccountChange}
                        onKeyDown={handleLinkedAccountInputKeyDown}
                    />
                    <IoAddCircle 
                        className={`add-aggregated-account-button ${!isValidLinkedAccount() ? 'disabled' : ''}`} 
                        onClick={handleAddLinkedAccount}
                    />
                </div>
            }
            <div className='linked-accounts-list-section'>
                {currentLinkedAccountsData.map((data, index) => (
                    <div key={index} className={`linked-account-item ${index === currentLinkedAccountsData.length - 1 ? 'last' : ''}`}>
                        <LinkedProfileCard index={index} isPreview={true} isEdit={true} profileDisplayData={data.profileDisplayData}/>
                        <IoCloseCircle 
                            className='remove-linked-account-icon' 
                            onClick={() => handleRemoveLinkedAccount(data)}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
  
export default LinkedAccountsEdit;

import LinkedAccountData from './LinkedAccountData';

class AccountUserProfileData {
    username: string;
    displayName: string;
    base64ProfileImage: string;
    qrCodeVerb: string;
    accountType: string;
    linkedAccountsData: LinkedAccountData[];

    constructor(
        username: string,
        displayName: string,
        base64ProfileImage: string,
        qrCodeVerb: string,
        accountType: string,
        linkedAccountsData: LinkedAccountData[]
    ) {
        this.username = username;
        this.displayName = displayName;
        this.base64ProfileImage = base64ProfileImage;
        this.qrCodeVerb = qrCodeVerb;
        this.accountType = accountType;
        this.linkedAccountsData = AccountUserProfileData.getValidLinkedAccountsData(linkedAccountsData);
    }

    static getValidLinkedAccountsData(linkedAccountsData: LinkedAccountData[]): LinkedAccountData[] {
        return linkedAccountsData
            .filter(item => item !== null && item.emailAddress !== null && item.profileDisplayData !== null)
            .map(item => new LinkedAccountData(item.emailAddress, item.profileDisplayData));
    }
}

export default AccountUserProfileData;

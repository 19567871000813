import './createaccountpage.scss';
import 'react-phone-input-2/lib/bootstrap.css';

import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import ApiResponse from '../../classes/ApiResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/getaccountstatefromtoken/GetAccountStateFromTokenResponse';
import LoadingIcon from '../../components/LoadingIcon';
import DefaultNavbar from '../../components/navbar/DefaultNavbar';
import PageContent from '../../components/PageContent';
import Constants from '../../configuration/constants';
import ChooseAccountTypePage from './ChooseAccountTypePage';
import CreateAggregatorAccountPage from './CreateAggregatorAccountPage';
import CreateIndividualAccountPage from './CreateIndividualAccountPage';
import { AccountType } from './types/types';

const CreateAccountPage = (): ReactElement => {
    const [selectedAccountType, setSelectedAccountType] = useState<AccountType|null>(null);

    const [loadingPage, setLoadingPage] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                null,
                true
            );
        };

        if (loadingPage) {
            getAccountStateFromToken().then(response => {
                if (!response || response instanceof AxiosError) {
                    navigate(Constants.authenticatePagePath);
                } else if (!response || response instanceof AxiosError) {
                    navigate(Constants.homePagePath);
                } else if (response.accountCreated && !response.stripeOnboardingCompleted) {
                    navigate(Constants.connectedAccountPagePath);
                } else if (response.accountCreated && response.stripeOnboardingCompleted) {
                    navigate(Constants.homePagePath);
                }
            });

            setLoadingPage(false);
        }
    }, [loadingPage, navigate]);

    if (loadingPage) {
        return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>;
    }

    if (!selectedAccountType) {
        return <ChooseAccountTypePage setSelectedAccountType={setSelectedAccountType}/>
    } else if (selectedAccountType === AccountType.INDIVIDUAL) {
        return <CreateIndividualAccountPage setSelectedAccountType={setSelectedAccountType}/>
    } else if (selectedAccountType === AccountType.AGGREGATOR) {
        return <CreateAggregatorAccountPage setSelectedAccountType={setSelectedAccountType}/>
    }

    return (
        <>
            <DefaultNavbar/>
            <PageContent>
                <h1 className='create-profile-title-text'>Error</h1>
            </PageContent>
        </>
    );
}

export default CreateAccountPage;

export type SocialMediaLinks = {
    website?: string;
    instagram?: string;
    tiktok?: string;
    facebook?: string;
    youtube?: string;
    spotify?: string;
}

export class SocialMediaPlatform {
    public static readonly WEBSITE = new SocialMediaPlatform('website', 'Website');
    public static readonly INSTAGRAM = new SocialMediaPlatform('instagram', 'Instagram');
    public static readonly TIKTOK = new SocialMediaPlatform('tiktok', 'TikTok');
    public static readonly FACEBOOK = new SocialMediaPlatform('facebook', 'Facebook');
    public static readonly YOUTUBE = new SocialMediaPlatform('youtube', 'YouTube');
    public static readonly SPOTIFY = new SocialMediaPlatform('spotify', 'Spotify');

    private constructor(
        public readonly value: string,
        public readonly displayName: string
    ) {}

    public static getAllSocialMediaPlatforms(): SocialMediaPlatform[] {
        return Object.values(SocialMediaPlatform).filter(value => value instanceof SocialMediaPlatform);
    }
}

import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { FaCircleCheck } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';

import PaymentProfileDisplayData
  from '../../classes/getprofiledisplaydata/PaymentProfileDisplayData';
import LoadingIcon from '../../components/LoadingIcon';
import PaymentNavbar from '../../components/navbar/PaymentNavbar';
import PageContent from '../../components/PageContent';
import HelperMethods from '../../helpers/HelperMethods';
import PaymentConfirmationPageProfile from './PaymentConfirmationPageProfile';

const PaymentConfirmationPage = (): ReactElement => {
  const { username } = useParams();

  const [paymentProfileDisplayData, setPaymentProfileDisplayData] = useState<PaymentProfileDisplayData|null>(null);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);

  useEffect(() => {
    const fetchProfileDisplayData = async () => {
      // Get the data from local storage
      const storedData = HelperMethods.getUserProfileDataFromLocalStorage(username);

      if (storedData instanceof PaymentProfileDisplayData) {
        setPaymentProfileDisplayData(storedData);
      }

      setLoadingPage(false);
    };
    
    if (loadingPage === true) {
      fetchProfileDisplayData();
    }
  }, [username, loadingPage]);

  if (loadingPage) {
    return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
  }
  
  return (
    <>
      <PaymentNavbar/>
      <PageContent>
        {(!paymentProfileDisplayData || !paymentProfileDisplayData.username) ?
          <>
            <FaCircleCheck className='thankyou-page-circle-check'/>
            <h2 className='payment-successful-text'>Payment Successful</h2>
          </>
          :
          <PaymentConfirmationPageProfile
            paymentProfileDisplayData={paymentProfileDisplayData}
            isPreview={false}
          />
        }
      </PageContent>
    </>
  );
};

export default PaymentConfirmationPage;

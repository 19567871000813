import PayoutSchedule from './PayoutSchedule';

class UpdatePayoutScheduleRequest {
    static type: string = 'UpdatePayoutScheduleRequest';
    
    payoutSchedule: PayoutSchedule;
      
    constructor(data: PayoutSchedule) {
        this.payoutSchedule = data;
    }
}

export default UpdatePayoutScheduleRequest;

import '../websitecontentstyle.scss';

import { ReactElement } from 'react';

import ContactUs from '../../../components/ContactUs';
import PageContent from '../../../components/PageContent';
import TextLink from '../../../components/TextLink';
import Constants from '../../../configuration/constants';

const PrivacyPolicyPage = (): ReactElement => {
    return (
        <PageContent alignItems='left' fullWidth>
            <h1>Privacy Policy</h1>

            <p>This Privacy Notice for {Constants.registeredCompanyName} (doing business as {Constants.tradingName}) ("{Constants.tradingName}", "we", "us", or "our"), describes how and why we might access, collect, store, use, and/or share (process') your personal information when you use our services ('Services'), including when you:</p>
            <ul>
                <li>Visit our website at {Constants.frontendURL}, or any website of ours that links to this Privacy Notice</li>
                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
            </ul>

            <p><b>Questions or concerns?</b> Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please <ContactUs/>.</p>
        
            <h2>Summary of Key Points</h2>
            <p><i>This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by finding the specific section below.</i></p>
            <p><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use.</p>
            <p><b>Do we process any sensitive personal information?</b> Some of the information may be considered 'special' or 'sensitive' in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.</p>
            <p><b>Do we collect any information from third parties?</b> We do not collect any information from third parties.</p>
            <p><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</p>
            <p><b>In what situations and with which types of parties do we share personal information?</b> We may share information in specific situations and with specific categories of third parties.</p>
            <p><b>How do we keep your information safe?</b> We have adequate organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
            <p><b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</p>
            <p><b>How do you exercise your rights?</b> The easiest way to exercise your rights is to <ContactUs/>. We will consider and act upon any request in accordance with applicable data protection laws.</p>

            <h2>What information do we collect?</h2>
            <p><b>Personal information you disclose to us</b></p>
            <p><i>In Short: We collect personal information that you provide to us.</i></p>
            <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
            <p><b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
            <ul>
                <li>Names</li>
                <li>Phone numbers</li>
                <li>Email addresses</li>
                <li>Mailing addresses</li>
                <li>Job titles</li>
                <li>Usernames</li>
                <li>Contact or authentication data</li>
                <li>Contact preferences</li>
                <li>Billing addresses</li>
            </ul>
            <p><b>Sensitive Information.</b> We do not process sensitive information.</p>
            <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

            <h2>Information automatically collected</h2>
            <p><i>In Short: Some information - such as your Internet Protocol (IP) address and/or browser and device characteristics - is collected automatically when you visit our Services.</i></p>
            <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information.</p>
            <p>This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes. Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our <TextLink href={`${Constants.homePagePath}${Constants.cookiePolicyPagePath}`} text='Cookie Policy'/>.</p>
            <p>The information we collect includes:</p>
            <ul>
                <li><i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).</li>
                <li><i>Device Data.</i> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
                <li><i>Location Data.</i> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
            </ul>

            <h2>How do we process your information?</h2>
            <p><i>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</i></p>
            <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
            <ul>
                <li><b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                <li><b>To deliver and facilitate delivery of services to the user.</b> We may process your information to provide you with the requested service.</li>
                <li><b>To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                <li><b>To send administrative information to you.</b> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
                <li><b>To enable user-to-user communications.</b> We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
                <li><b>To request feedback.</b> We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
                <li><b>To send you marketing and promotional communications.</b> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time.</li>
                <li><b>To protect our Services.</b> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
                <li><b>To identify usage trends.</b> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
                <li><b>To determine the effectiveness of our marketing and promotional campaigns.</b> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
                <li><b>To save or protect an individual's vital interest.</b> We may process your information when necessary to save or protect an individual's vital interest, such as to prevent harm.</li>
            </ul>

            <h2>What legal bases do we rely on to process your information?</h2>
            <p><i>In Short: We only process, your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</i></p>
            <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
            <ul>
                <li><b>Consent.</b> We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time.</li>
                <li><b>Performance of a Contract.</b> We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
                <li><b>Legitimate Interests.</b> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                    <ul>
                        <li>Send users information about special offers and discounts on our products and services</li>
                        <li>Analyse how our Services are used so we can improve them to engage and retain users</li>
                        <li>Support our marketing activities</li>
                        <li>Diagnose problems and/or prevent fraudulent activities</li>
                        <li>Understand how our users use our products and services so we can improve user experience</li>
                    </ul>
                </li>
                <li><b>Legal Obligations.</b> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
                <li><b>Vital Interests.</b> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
            </ul>
            <p>In legal terms, we are generally the 'data controller' under European data protection laws of the personal information described in this Privacy Notice, since we determine the means and/or purposes of the data processing we perform. This Privacy Notice does not apply to the personal information we process as a 'data processor' on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing agreement is the 'data controller' responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers' privacy practices, you should read their privacy policies and direct any questions you have to them.</p>
        
            <h2>When and with whom do we share your personal information?</h2>
            <p><i>In Short: We may share information in specific situations described in this section and/or with the following categories of third parties.</i></p>
            <p>Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organisation apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.</p>
            <p>The categories of third parties we may share personal information with are as follows:</p>
            <ul>
                <li>Data Storage Service Providers</li>
                <li>Payment Processors</li>
                <li>Cloud Computing Services</li>
            </ul>
            <p>We also may need to share your personal information in the following situations:</p>
            <ul>
                <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li><b>Other Users.</b> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</li>
            </ul>

            <h2>Do we use cookies and other tracking technologies?</h2>
            <p><i>In Short: We may use cookies and other tracking technologies to collect and store your information.</i></p>
            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</p>
            <p>We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our <TextLink href={`${Constants.frontendURL}${Constants.cookiePolicyPagePath}`} text='Cookie Policy'/>.</p>

            <h2>How long do we keep your information?</h2>
            <p><i>In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this Privacy Notice unless otherwise required by law.</i></p>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

            <h2>How do we keep your information safe?</h2>
            <p><i>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</i></p>
            <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>

            <h2>Do we collect data from minors?</h2>
            <p><i>In Short: We do not knowingly collect data from or market to children under 18 years of age.</i></p>
            <p>We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please <ContactUs/>.</p>

            <h2>What are your privacy rights?</h2>
            <p><i>In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Switzerland, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</i></p>
            <p>In some regions (like the EEA, UK, and Switzerland), you have certain rights under applicable data protection laws. These may include the right i) to request access and obtain a copy of your personal information, (il) to request rectification or erasure; (iii to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. To request this please <ContactUs/>.</p>
            <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
            <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.</p>
            <p>If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</p>
            <p><b>Withdrawing your consent:</b> If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time.</p>
            <p>To update your consent at any time please <ContactUs/> or update your preferences.</p>
            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
            <p><b>Opting out of marketing and promotional communications:</b> To unsubscribe from our marketing and promotional communications at any time please <ContactUs/>. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
            <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
            <ul>
                <li>Log in to your account settings and update your user account.</li>
            </ul>
            <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
            <p><b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. For further information, please see our <TextLink href={`${Constants.frontendURL}${Constants.cookiePolicyPagePath}`} text='Cookie Policy'/>.</p>
            <p>If you have questions or comments about your privacy rights, you may <ContactUs/>.</p>

            <h2>Controls for Do-Not-Track features</h2>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.</p>

            <h2>Do we make updates to this notice?</h2>
            <p><i>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</i></p>
            <p>We may update this Privacy Notice from time to time. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.</p>

            <h2>How can you contact us about this notice?</h2>
            <p>If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO) through our <ContactUs/> page.</p>

            <h2>How can you review, update or delete the data we collect from you?</h2>
            <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please sign-in to your account or <ContactUs/>.</p>
        
            <p>This policy was last updated November 9, 2024.</p>
        </PageContent>
    );
}

export default PrivacyPolicyPage;

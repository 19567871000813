export class IdentifierType {
    public static readonly USERNAME = new IdentifierType('USERNAME');
    public static readonly EMAIL_ADDRESS = new IdentifierType('EMAIL_ADDRESS');

    private constructor(
        public readonly value: string,
    
    ) {}

    public static getAllIdentifierTypes(): IdentifierType[] {
        return Object.values(IdentifierType).filter(value => value instanceof IdentifierType);
    }
}

import getSymbolFromCurrency from 'currency-symbol-map';
import Cookies from 'js-cookie';

import AggregatorProfileDisplayData
  from '../classes/getprofiledisplaydata/AggregatorProfileDisplayData';
import CompanyPaymentProfileDisplayData
  from '../classes/getprofiledisplaydata/CompanyPaymentProfileDisplayData';
import IndividualPaymentProfileDisplayData
  from '../classes/getprofiledisplaydata/IndividualPaymentProfileDisplayData';
import PaymentProfileDisplayData
  from '../classes/getprofiledisplaydata/PaymentProfileDisplayData';
import ProfileDisplayData
  from '../classes/getprofiledisplaydata/ProfileDisplayData';
import AggregatorAccountUserProfileData
  from '../classes/getprofilefromtoken/AggregatorAccountUserProfileData';
import PaymentAccountUserProfileData
  from '../classes/getprofilefromtoken/PaymentAccountUserProfileData';
import LocalStorageItem from '../classes/LocalStorageItem';
import Constants from '../configuration/constants';
import { AccountType } from '../pages/createaccount/types/types';
import { SocialMediaLinks } from '../types/types';
import Logger from './Logger';

class HelperMethods {
  public static getValidButtonValues(values: number[]): number[] {
    if (!values) {
      return [];
    }
  
    return values
      .filter(value => (Constants.minimumTransactionAmount <= value && value <= Constants.maximumTransactionAmount))
      .map(value => HelperMethods.roundValue(value))
      .sort((a, b) => a - b)
      .slice(0, Constants.maxPaySelectionButtons);
  }

  private static roundValue(value: number): number {
    return Math.round(value*100)/100;
  }

  public static getTotalFee = (value: number): number => {
    const total: number = (value + Constants.paymentProcessingFeeConstant + Constants.platformFeeConstant) / (1 - Constants.paymentProcessingFeeMultiplier - Constants.platformFeeMultiplier);

    return total - value;
  }

  public static getValidSocialMediaLinks(obj: SocialMediaLinks): { [k: string]: string; } {
    if (!obj || Object.entries(obj).length === 0) {
      return {};
    } else {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, value]) => value && value !== '')
          .map(([key, value]) => {
            return [key, HelperMethods.getValidUrl(value)];
          })
      );
    }
  }

  public static getValidUrl(value: string): string {
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      return 'https://' + value;
    }

    return value;
  }

  public static getValidLinkedAccountEmailAddresses(linkedAccountEmailAddresses: string[]): string[] {
    if (!linkedAccountEmailAddresses) {
      return [];
    } else {
      return Array.from(new Set(
        linkedAccountEmailAddresses.filter(emailAddress => emailAddress && emailAddress.trim().length > 0)
      ));
    }
  }

  public static getValidPhoneNumber(value: string): string {
    if (value && !value.startsWith('+')) {
      return '+' + value;
    }

    return value;
  }

  public static cloneWithUpdates<T>(
    instance: T,
    updates: Partial<T>
  ): T {
    // Create a new instance with the same prototype as the original
    const clone = Object.create(Object.getPrototypeOf(instance));
  
    // Merge the original instance properties and updates into the new instance
    return Object.assign(clone, instance, updates);
  }

  public static getOrdinalSuffix(num: number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = num % 100;
    return num + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  }

  public static capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public static signOut(): void {
    Cookies.remove(Constants.jwtTokenName);
    window.location.reload();
  }

  public static getUserProfileDataFromLocalStorage(username: string | undefined): ProfileDisplayData | null {
    const storedData = localStorage.getItem(`${Constants.localStorangeUserProfileDataKeyPrefix}${username}`);

    if (!storedData) {
      return null;
    }
  
    try {
      const parsedData = JSON.parse(storedData) as LocalStorageItem;
      if (!parsedData || !parsedData.timestamp || !parsedData.data) {
        return null;
      }

      const now = new Date().getTime();

      if (now - parsedData.timestamp > Constants.maxLocalStorageAgeInMilis) {
        return null;
      }

      return this.getProfileDisplayDataInstanceFromData(parsedData.data as ProfileDisplayData);
    } catch (error) {
      Logger.error('Error parsing stored user profile data: ' + error);
      return null;
    }
  };

  public static putLocalStorageItem(key: string, data: any): void {
    if (!data) {
      return;
    }
    
    localStorage.setItem(
      key,
      JSON.stringify(new LocalStorageItem(data))
    );
  };

  public static getFeeString(multiplier: number, constant: number, currency: string): string {
    let multiplierString = '';
    let constantString = '';

    if (multiplier > 0) {
      multiplierString = (Math.round(multiplier * 10000) / 100).toString();
      multiplierString = multiplierString.replace(/\.?0+$/, '') + '%';
    }
    if (constant > 0) {
      constantString = HelperMethods.getCurrencySymbolFromString(currency) + (Math.round(constant * 100) / 100).toString();
      constantString = constantString.replace(/\.?0+$/, '');
    }

    return multiplierString + (constantString !== '' ? (' + ' + constantString) : '')
  }

  public static getPaymentProfileDisplayDataFromPaymentAccountUserProfileData(userProfileData: PaymentAccountUserProfileData): PaymentProfileDisplayData {
    return new PaymentProfileDisplayData(
      userProfileData.username,
      userProfileData.displayName,
      userProfileData.base64ProfileImage, 
      userProfileData.qrCodeVerb,
      userProfileData.accountType,
      userProfileData.suggestedPayValues,
      userProfileData.thankyouMessage,
      userProfileData.socialMediaLinks,
      userProfileData.stripeConnectedAccountId,
      userProfileData.currency
    );
  }

  public static getAggregatorProfileDisplayDataFromAggregatorAccountUserProfileData(userProfileData: AggregatorAccountUserProfileData): AggregatorProfileDisplayData {
    return new AggregatorProfileDisplayData(
      userProfileData.username,
      userProfileData.displayName,
      userProfileData.base64ProfileImage, 
      userProfileData.qrCodeVerb,
      userProfileData.linkedAccountsData
    );
  }

  public static getProfileDisplayDataInstanceFromData(profileDisplayData: ProfileDisplayData | null): ProfileDisplayData | null {
    if (!profileDisplayData) {
      return null;
    }

    switch (profileDisplayData.accountType) {
        case AccountType.INDIVIDUAL.value:
            const individual = profileDisplayData as IndividualPaymentProfileDisplayData;
            return new IndividualPaymentProfileDisplayData(
                individual.username,
                individual.displayName,
                individual.base64ProfileImage,
                individual.qrCodeVerb,
                individual.suggestedPayValues,
                individual.thankyouMessage,
                individual.socialMediaLinks,
                individual.stripeConnectedAccountId,
                individual.currency
            );
        case AccountType.COMPANY.value:
            const company = profileDisplayData as CompanyPaymentProfileDisplayData;
            return new CompanyPaymentProfileDisplayData(
                company.username,
                company.displayName,
                company.base64ProfileImage,
                company.qrCodeVerb,
                company.suggestedPayValues,
                company.thankyouMessage,
                company.socialMediaLinks,
                company.stripeConnectedAccountId,
                company.currency
            );
        case AccountType.AGGREGATOR.value:
            const aggregator = profileDisplayData as AggregatorProfileDisplayData;
            return new AggregatorProfileDisplayData(
                aggregator.username,
                aggregator.displayName,
                aggregator.base64ProfileImage,
                aggregator.qrCodeVerb,
                aggregator.linkedAccountsData
            );
        default:
            Logger.error('Invalid Account Type returned from GetProfileDisplayDataResponse: ' + profileDisplayData.accountType);
            return null;
    }
  }

  public static getLinkedAccountProfileDisplayDataInstanceFromData(profileDisplayData: ProfileDisplayData): ProfileDisplayData {
    switch (profileDisplayData.accountType) {
        case AccountType.INDIVIDUAL.value:
            const individual = profileDisplayData as IndividualPaymentProfileDisplayData;
            return new IndividualPaymentProfileDisplayData(
                individual.username,
                individual.displayName,
                individual.base64ProfileImage,
                individual.qrCodeVerb,
                individual.suggestedPayValues,
                individual.thankyouMessage,
                individual.socialMediaLinks,
                individual.stripeConnectedAccountId,
                individual.currency
            );
        case AccountType.COMPANY.value:
            const company = profileDisplayData as CompanyPaymentProfileDisplayData;
            return new CompanyPaymentProfileDisplayData(
                company.username,
                company.displayName,
                company.base64ProfileImage,
                company.qrCodeVerb,
                company.suggestedPayValues,
                company.thankyouMessage,
                company.socialMediaLinks,
                company.stripeConnectedAccountId,
                company.currency
            );
        case AccountType.AGGREGATOR.value:
          const aggregator = profileDisplayData as AggregatorProfileDisplayData;
          return new AggregatorProfileDisplayData(
              aggregator.username,
              aggregator.displayName,
              aggregator.base64ProfileImage,
              aggregator.qrCodeVerb,
              aggregator.linkedAccountsData
          );
        default:
          Logger.error('Invalid Account Type returned from GetProfileDisplayDataResponse: ' + profileDisplayData.accountType);
          return new ProfileDisplayData('', '', '', '', '');
    }
  }

  public static getCurrencySymbolFromString(str: string): string {
    return getSymbolFromCurrency(str.toUpperCase()) || '£';
  }
}

export default HelperMethods;

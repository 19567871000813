import '../websitecontentstyle.scss';

import { ReactElement } from 'react';

import PageContent from '../../../components/PageContent';
import TextLink from '../../../components/TextLink';
import Constants from '../../../configuration/constants';

const AllocationOfTipsActPage = (): ReactElement => {
    return (
        <PageContent alignItems='left'>
            <h1>Allocation of Tips Act (2023)</h1>
            <img className='tax-on-tips-image' src={require('../../../assets/images/websitecontent/tax-calculator.jpg')}/>
            <p>The <i>Employment (Allocation of Tips) Act 2023</i>, effective <b>October 1, 2024</b>, represents a significant shift in how businesses handle gratuities and tips. This legislation mandates that 100% of tips, service charges, and gratuities go directly to workers, with strict guidelines on fair distribution and transparency.</p>
            <p>For many business owners, this Act introduces a complex administrative challenge. How can you ensure complete compliance without dedicating hours to tracking, recording, and distributing tips? Enter {Constants.tradingName} - a revolutionary solution that transforms tip management.</p>
            <p>Our platform eliminates the administrative headache by design. When customers leave a tip through {Constants.tradingName}, the money goes directly to the intended recipient, completely bypassing the business bank account. This means instant, automatic compliance with the Act's core requirements.</p>
            <p>Imagine removing the entire infrastructure of tip tracking, policy documentation, and distribution records. With {Constants.tradingName}, that's exactly what happens. Tips are transparently allocated to workers in real-time, with digital records that satisfy the Act's stringent reporting requirements.</p>
            <p>Business owners can now focus on what they do best - running their establishment - while we handle the intricate details of tip management. No more complicated spreadsheets, no more potential compliance risks, just simple, fair tip distribution.</p>
            <p>The potential impact is significant. The Act could redistribute approximately £200 million in tips annually, ensuring workers receive fair compensation. And with {Constants.tradingName}, businesses can be at the forefront of this positive change, effortlessly.</p>
            <p>Read more about the Allocation of Tips Act <TextLink text='here' href='https://www.legislation.gov.uk/ukpga/2023/13'/>.</p>
        </PageContent>
    );
}

export default AllocationOfTipsActPage;

import HelperMethods from '../../helpers/HelperMethods';
import ProfileDisplayData from '../getprofiledisplaydata/ProfileDisplayData';

class LinkedAccountData {
    static type: string = 'LinkedAccountData';
    
    emailAddress: string;
    profileDisplayData: ProfileDisplayData;
  
    constructor(emailAddress: string, profileDisplayData: ProfileDisplayData) {
        this.emailAddress = emailAddress;
        this.profileDisplayData = HelperMethods.getLinkedAccountProfileDisplayDataInstanceFromData(profileDisplayData);
    }
}

export default LinkedAccountData;
